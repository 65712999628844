export const apiRequestResponseMsg = {
  BAD_CREDENTIALS: 'AUTHENTICATION_BAD_CREDENTIALS',
  WEBSITE_NOT_FOUND: 'AUTHENTICATION_WEBSITE_NOT_FOUND',
  USER_NOT_FOUND: 'AUTHENTICATION_USER_NOT_FOUND',
  INVALID_DATA: 'INVALID_DATA',
  WHITELISTED_DOMAINS_MAX_COUNT: 'WHITELISTED_DOMAINS_NOT_UPDATABLE',
  ALREADY_EXISTING_USER: 'ALREADY_EXISTING_USER',
  ALREADY_EXISTING_DYNAMIC_PAGE: 'ALREADY_EXISTING_DYNAMIC_PAGE',
  ALREADY_EXISTING_PATTERN: 'ALREADY_EXISTING_PATTERN',
  NOT_FOUND_SSR_SETTINGS: 'NOT_FOUND_SSR_SETTINGS',
  WEBSITE_REMOVE_FAIL_EXTERNAL: 'WEBSITE_REMOVE_FAIL_EXTERNAL',
  SUBSCRIPTION_CANCEL_FAIL_EXTERNAL: 'SUBSCRIPTION_CANCEL_FAIL_EXTERNAL',
  WEEBLY_WEBSITE_DOMAIN_NOT_CONNECTED: 'WEEBLY_WEBSITE_DOMAIN_NOT_CONNECTED',
  ALREADY_EXISTING_HEATMAP: 'ALREADY_EXISTING_HEATMAP',
  INVALID_VOUCHER_CODE: 'INVALID_VOUCHER_CODE',
  WEBSITES_BAD_CREDENTIALS: 'Bad credentials.',
  SSR_WEBSITE_NOT_EXISTING: 'SSR_WEBSITE_NOT_EXISTING',
  NOT_ACTIVE: 'AUTHENTICATION_USER_PENDING',
  ALREADY_USED_PAYMENT_VOUCHER: 'ALREADY_USED_PAYMENT_VOUCHER',
  INVALID_PAYMENT_VOUCHER: 'INVALID_PAYMENT_VOUCHER',
  NOT_FOUND_WEBSITE: 'NOT_FOUND_WEBSITE',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
};

export enum apiStatus {
  NONE = 'NONE',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
}

export const buttons = {
  PRIMARY: 'PRIMARY',
  SECONDARY: 'SECONDARY',
};
