import { EXPORT_FORMAT } from '@va/constants';
import {
  EXPORT_VISITORS_CANCEL,
  EXPORT_VISITORS_FAILED,
  EXPORT_VISITORS_REQUEST,
  EXPORT_VISITORS_SUCCEEDED,
  exportVisitorsCancel,
  exportVisitorsFailed,
  exportVisitorsPageFailed,
  exportVisitorsPageSucceeded,
  exportVisitorsSucceeded,
  GET_LATEST_VISITORS_BROWSERS,
  GET_LATEST_VISITORS_OSS,
  getLatestVisitorsBrowsersFailed,
  getLatestVisitorsBrowsersSucceeded,
  getLatestVisitorsOssFailed,
  getLatestVisitorsOssSucceeded,
  LATEST_VISITORS_MAP_REQUEST,
  requestLatestVisitorsMapFailed,
  requestLatestVisitorsMapSucceeded,
  requestVisitorInformationFailed,
  requestVisitorInformationSucceeded,
  VISITOR_REQUEST,
} from '@va/dashboard/actions/api';
import Api from '@va/dashboard/api-client/index';
import { isTrackingEnabled } from '@va/dashboard/selectors/api';
import { getInstanceId } from '@va/dashboard/selectors/app';
import { getUrlViewFlag, getWebsiteTimezone, getWebsiteUrl } from '@va/dashboard/selectors/core';
import { getTrafficFilter } from '@va/dashboard/selectors/ui';
import { getFilterWithSearchType } from '@va/dashboard/util-helpers/filters';
import { getHeaderForExportVisitors, processExportVisitorsData, processExportVisitorsDataXLS } from '@va/http-client';
import { getMaxExportLengthPerPage, getUrlWithoutProtocolAndTrailingSlash } from '@va/util/helpers';
import moment from 'moment';
import { all, call, cancel, cancelled, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import { downloadExportResponse } from './core/Util';

export function* watchers() {
  yield all([watchVisitor(), watchLatestVisitorsList(), watchLatestVisitorsMap(), watchExportVisitors()]);
}

export function fetch(apiCall, successCallback, failureCallback, action) {
  return fetchCustom(action, apiCall, successCallback, failureCallback);
}

export function* fetchCustom(action, apiCall, successCallback, failureCallback, preRequest, postRequest) {
  try {
    const websiteId = yield select(getInstanceId);
    const filter = preRequest ? preRequest(action.filter) : action.filter;
    const trafficFilter = yield select(getTrafficFilter);
    const data = yield call(apiCall, websiteId, filter, action.handleSpecificYear ? trafficFilter.specificYear : null);
    yield put(successCallback(postRequest ? postRequest(data) : data));
  } catch (error) {
    yield put(failureCallback(error));
  }
}

export function* fetchVisitor(action) {
  try {
    const websiteId = yield select(getInstanceId);
    const data = yield call(Api.getVisitor, websiteId, action.id);
    yield put(requestVisitorInformationSucceeded(data));
  } catch (error) {
    yield put(requestVisitorInformationFailed(error));
  }
}

export function* watchVisitor() {
  yield takeLatest(VISITOR_REQUEST, fetchVisitor);
}

export function* watchLatestVisitorsList() {
  yield takeLatest(GET_LATEST_VISITORS_OSS, getLatestVisitorsOss);
  yield takeLatest(GET_LATEST_VISITORS_BROWSERS, getLatestVisitorsBrowsers);
}

export function* getLatestVisitorsOss() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getLatestVisitorsOss, websiteId);
    yield put(getLatestVisitorsOssSucceeded({ list: response }));
  } catch (error) {
    yield put(getLatestVisitorsOssFailed(error));
  }
}

export function* getLatestVisitorsBrowsers() {
  try {
    const websiteId = yield select(getInstanceId);
    const response = yield call(Api.getLatestVisitorsBrowsers, websiteId);
    yield put(getLatestVisitorsBrowsersSucceeded({ list: response }));
  } catch (error) {
    yield put(getLatestVisitorsBrowsersFailed(error));
  }
}

export function* watchLatestVisitorsMap() {
  yield takeLatest(
    LATEST_VISITORS_MAP_REQUEST,
    fetch,
    Api.getLatestVisitorsMap,
    requestLatestVisitorsMapSucceeded,
    requestLatestVisitorsMapFailed,
  );
}

export function* watchExportVisitors() {
  yield fork(takeExportVisitorsActions);
}

export function* takeExportVisitorsActions() {
  while (true) {
    let action = yield take(EXPORT_VISITORS_REQUEST);
    const exportTask = yield fork(tryToExportVisitors, action);

    action = yield take([EXPORT_VISITORS_CANCEL, EXPORT_VISITORS_FAILED, EXPORT_VISITORS_SUCCEEDED]);
    if (action.type === EXPORT_VISITORS_CANCEL) {
      yield cancel(exportTask);
    }
  }
}

export function* tryToExportVisitors(action) {
  try {
    yield call(exportVisitors, action);
  } finally {
    if (yield cancelled()) {
      yield put(exportVisitorsCancel());
    }
  }
}

/**
 * @deprecated
 * TODO Delete
 * @param action
 * @returns {Generator<*, void, *>}
 */
export function* exportVisitors(action) {
  const translate = action.translate;
  const timezone = yield select(getWebsiteTimezone);
  const websiteId = yield select(getInstanceId);
  const urlviewFlag = yield select(getUrlViewFlag);
  const websiteUrl = yield select(getWebsiteUrl);
  const isTrackingFeature = yield select(isTrackingEnabled);
  let data;
  let spreadsheetData = getHeaderForExportVisitors(translate, action.format);

  const newFilter = Object.assign({}, action.filter, { start: 0, length: 0 });
  data = yield call(Api.getLatestVisitorsList, websiteId, newFilter);

  const length = getMaxExportLengthPerPage(data.sessionsTotal);

  const requiredRequests = data.sessionsTotal / length;
  for (let i = 0; i < requiredRequests; i++) {
    data = yield call(getExportVisitorsPage, action.filter, length, i);
    if (data === null) {
      return;
    }

    if (action.format === EXPORT_FORMAT.CSV) {
      spreadsheetData += processExportVisitorsData(data.data, translate, timezone, urlviewFlag, isTrackingFeature);
    } else {
      const processedData = processExportVisitorsDataXLS(
        data.data,
        translate,
        timezone,
        urlviewFlag,
        isTrackingFeature,
      );
      for (let i = 0; i < processedData.length; i++) {
        spreadsheetData.push(processedData[i]);
      }
    }
  }

  let { from, until } = action.filter;

  from = moment(from).tz(window.timezone).format('YYYY-MM-DD');
  until = moment(until).tz(window.timezone).format('YYYY-MM-DD');

  const fileName = `${getUrlWithoutProtocolAndTrailingSlash(websiteUrl)}_${from}_${until}_visitors`;
  const response = yield call(downloadExportResponse, spreadsheetData, fileName, action.format);
  yield put(exportVisitorsSucceeded(response));
}

/**
 * @deprecated
 * TODO Delete
 * @param filter
 * @param length
 * @param page
 * @returns {Generator<*, *|null, *>}
 */
export function* getExportVisitorsPage(filter, length, page) {
  try {
    const websiteId = yield select(getInstanceId);
    const filterWithSearchType = getFilterWithSearchType(filter);
    const newFilter = Object.assign({}, filterWithSearchType, {
      start: page * length,
      length: length,
    });
    const data = yield call(Api.getLatestVisitorsList, websiteId, newFilter);

    yield put(
      exportVisitorsPageSucceeded({
        sessions: data.data,
        sessionsTotal: data.sessionsTotal,
      }),
    );

    return data;
  } catch (error) {
    yield put(exportVisitorsPageFailed(error));
    yield put(exportVisitorsFailed(error));
    return null;
  }
}
