import { getInstanceId } from '@va/dashboard/selectors/app';
import { isWixWebsite } from '@va/dashboard/selectors/core';
import { patch, post, remove } from '@va/http-client';
import { useAsyncFunction, useFetchData } from '@va/util/hooks';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ReportTypeEnum } from './notification-preferences/useNotificationPreferences';

export const useUpdateUrlView = () => {
  const websiteId = useSelector(getInstanceId);
  const fetcher = useCallback(
    (urlView: string) => patch(`/websites/${websiteId}/frontend-flags`, {}, { urlView }),
    [websiteId],
  );
  return useAsyncFunction<typeof fetcher>(fetcher);
};

export const useAddNewWhitelistedDomain = () => {
  const websiteId = useSelector(getInstanceId);
  const addNewWhitelistedDomain = useCallback(
    async (domain: string) => {
      try {
        await post(`/websites/${websiteId}/whitelisted-domains`, {}, { domain });
      } catch (error) {
        console.log((error as any).message);
      }
    },
    [websiteId],
  );
  return { addNewWhitelistedDomain };
};

export const useRemoveWhitelistedDomain = () => {
  const websiteId = useSelector(getInstanceId);
  const removeWhitelistedDomain = useCallback(
    async (domain: string) => {
      try {
        await remove(`/websites/${websiteId}/whitelisted-domains/${domain}`);
      } catch (error) {
        console.log((error as any).message);
      }
    },
    [websiteId],
  );
  return { removeWhitelistedDomain };
};

export const useChangeTimezone = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const url = isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  const changeTimezone = useCallback(
    (timezone: string) => {
      return patch(url, {}, { timezone });
    },
    [url],
  );

  return useAsyncFunction<typeof changeTimezone>(changeTimezone);
};

export const useChangeFirstWeekDay = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const url = isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`;

  const fetcher = useCallback(
    (firstWeekDay: number) => {
      return patch(url, {}, { firstWeekDay });
    },
    [url],
  );

  return useAsyncFunction<typeof fetcher>(fetcher);
};

export const useUnclaimWebsite = () => {
  const websiteId = useSelector(getInstanceId);

  const fetcher = useCallback(() => {
    return post(`/migrations/websites/${websiteId}/unclaim`, {}, {});
  }, [websiteId]);

  return useAsyncFunction<typeof fetcher>(fetcher);
};

export type NotificationSettingsResponse = {
  payload: NotificationSettings;
};

export type NotificationSettings = {
  reportType: ReportTypeEnum;
  websiteKey: string;
  emailList: EmailItem[];
};

export type EmailItem = {
  value: string;
  verified: boolean;
};

export const useGetNotificationSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const mapper = useCallback((data: NotificationSettingsResponse) => data.payload, []);
  return useFetchData<NotificationSettings, Error>(
    `/v2/websites/${websiteId}/notification-settings`,
    undefined,
    mapper,
  );
};

export const useUpdateNotificationSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = useCallback(
    (data: { reportType?: ReportTypeEnum; emailList?: EmailItem[] }) =>
      patch(`/v2/websites/${websiteId}/notification-settings`, {}, data),
    [websiteId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useAddNotificationSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = useCallback(
    (data: { email: string }) => post(`/v2/websites/${websiteId}/notification-settings/email`, {}, data),
    [websiteId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc, { throwErrorBack: true });
};

export const useRemoveNotificationSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = useCallback(
    (data: { email: string }) => remove(`/v2/websites/${websiteId}/notification-settings/email`, {}, data),
    [websiteId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useConfirmNotificationSettingsEmail = () => {
  const asyncFunc = useCallback(
    (data: { email: string; token: string }, websiteId: string) =>
      post(`/v2/websites/${websiteId}/notification-settings/confirm`, {}, data),
    [],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useResendNotificationSettingsEmail = () => {
  const websiteId = useSelector(getInstanceId);
  const asyncFunc = useCallback(
    (data: { email: string }) => post(`/v2/websites/${websiteId}/notification-settings/resend-confirmation`, {}, data),
    [websiteId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};

export const useUpdateWebsiteSettings = () => {
  const websiteId = useSelector(getInstanceId);
  const isWix = useSelector(isWixWebsite);
  const asyncFunc = useCallback(
    (data: Record<string, unknown>) => patch(isWix ? `/wix/websites/${websiteId}` : `/websites/${websiteId}`, {}, data),
    [isWix, websiteId],
  );
  return useAsyncFunction<typeof asyncFunc>(asyncFunc);
};
