import { EVENTS, TEST_IDS } from '@va/constants';
import { DataViewButton, ParagraphWithTooltip, TitleInput, fontWeights, paragraphSizes } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import { useCustomizationContext, useEventsContext, usePermissionsProvider } from '@va/util/misc';
import classNames from 'classnames';
import { memo } from 'react';
import { useReportBlockCtx } from './report-block-ctx';

export const ReportBlockTitle = memo(() => {
  const { title, titleTooltip, titleTooltipOptions, visualization, size = 'medium' } = useReportBlockCtx();
  const { canEditTitle } = usePermissionsProvider();
  const { localEventBus } = useEventsContext();
  const { getCustomValue } = useCustomizationContext();

  const customRBTitle = getCustomValue('initialTitle', typeof title === 'string' ? title : String(title));
  return (
    <div
      className={classNames('flex items-center', {
        'gap-12px': size === 'medium',
        'gap-9px': size === 'small',
        'overflow-hidden': !canEditTitle,
      })}
    >
      {visualization && <DataViewButton {...visualization} size={size} />}
      {canEditTitle ? (
        <TitleInput
          name={'title'}
          initialValue={customRBTitle}
          onSave={(title: string) => {
            localEventBus?.dispatch(EVENTS.editRBName, title);
          }}
        />
      ) : (
        <TooltipWrapper
          trigger='click'
          interactive
          disabled={!titleTooltip}
          content={titleTooltip}
          {...titleTooltipOptions}
        >
          <div className={'truncate'}>
            <ParagraphWithTooltip
              data-testid={TEST_IDS.generic.title}
              className={classNames('inline', { 'underline-dashed cursor-default': titleTooltip })}
              weight={fontWeights.medium}
              size={size === 'medium' ? paragraphSizes.large : paragraphSizes.medium}
            >
              {customRBTitle}
            </ParagraphWithTooltip>
          </div>
        </TooltipWrapper>
      )}
    </div>
  );
});
