import { Selectors3asFPermissions } from '@va/aaas/shared/selectors';
import { is3asApp, isWixMiniApp } from '@va/util/helpers';
import { getFeaturePermissions, isWebsitePlanAdvancedOrHigher } from '.';

export const isCompanyRevealFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.isEnabled.ipToCompany(state);
  }

  const featurePermissions = getFeaturePermissions(state) as any;
  return !!featurePermissions.ip2company;
};

export const isVisitorSegmentsFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.isEnabled.visitorSegments(state);
  }
  const featurePermissions = getFeaturePermissions(state) as any;
  return !!featurePermissions.visitorSegments;
};

export const isCountryBasedPrivacyModeEnabled = (state: any) => {
  if (is3asApp() || isWixMiniApp()) {
    return Selectors3asFPermissions.isEnabled.countryBasedPrivacy(state);
  }

  return isWebsitePlanAdvancedOrHigher(state);
};

export const isCustomDashboardFeatureEnabled = (state: any) => {
  if (is3asApp()) {
    return Selectors3asFPermissions.isEnabled.customDashboards(state);
  }

  const featurePermissions = getFeaturePermissions(state) as any;
  return !!featurePermissions.customDashboard;
};
