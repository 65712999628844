import { fontWeights, GrayGradientDivider, paragraphSizes, ParagraphWithTooltip } from '@va/ui/design-system';
import { TooltipWrapper } from '@va/ui/tooltips';
import classNames from 'classnames';
import { ReactElement, useEffect, useState } from 'react';
import Switch from 'react-switch';

export type ToggleActionCardType = {
  title?: string;
  subtitle?: string;
  handleChange?: (value: { name: string; value: boolean }) => void;
  name: string;
  value: boolean;
  icon?: () => ReactElement;
  children?: ReactElement;
  wrapperClass?: string;
  heightClass?: string;
  titleClass?: string;
  subSectionClass?: string;
  disabled?: boolean;
  infoTooltip?: string;
  switchOnColor?: string;
  className?: string;
  size?: 'small' | 'medium' | 'large';
  'data-testid-switch'?: string;
};

const switchSizes = {
  small: {
    width: 30,
    height: 18,
    handleDiameter: 14,
  },
  medium: {
    width: 30,
    height: 18,
    handleDiameter: 14,
  },
  large: {
    width: 48,
    height: 30,
    handleDiameter: 24,
  },
};

export const ToggleActionCard = ({
  title,
  subtitle,
  value,
  icon: Icon,
  wrapperClass,
  subSectionClass,
  name,
  handleChange,
  children,
  titleClass,
  disabled = false,
  infoTooltip,
  switchOnColor,
  className,
  size = 'large',
  heightClass,
  ...rest
}: ToggleActionCardType) => {
  const [toggleChecked, setToggleChecked] = useState(false);

  useEffect(() => {
    setToggleChecked(value);
  }, [value]);

  const handleToggleClick = () => {
    if (handleChange) {
      handleChange({ name: name, value: !toggleChecked });
    } else {
      setToggleChecked(!toggleChecked);
    }
  };

  return (
    <div className={classNames('flex rounded-18 bg-white-snow justify-between items-center flex-col', wrapperClass)}>
      <div
        className={classNames(
          'flex w-full p-3 rounded-t-18 bg-white-snow justify-between items-center gap-2',
          { 'rounded-18': !children },
          {
            'rounded-b-18': !toggleChecked && children,
            'min-h-60px': size === 'large',
            'h-42px p-3 !rounded-10.5': size === 'medium',
          },
          heightClass,
          className,
        )}
      >
        <div className='flex items-center gap-18px truncate'>
          {Icon && (
            <div className='flex-shrink-0'>
              <Icon />
            </div>
          )}
          <div className='flex flex-col w-full truncate'>
            {title && (
              <ParagraphWithTooltip
                weight={fontWeights.medium}
                className={classNames('truncate', titleClass, {
                  'py-1': size === 'large',
                  '!leading-18': size === 'small',
                })}
              >
                {title}
              </ParagraphWithTooltip>
            )}

            {subtitle && (
              <ParagraphWithTooltip
                colorClassName='text-devil-gray'
                weight={fontWeights.medium}
                className='truncate text-12 leading-18'
                size={paragraphSizes.tiny}
              >
                {subtitle}
              </ParagraphWithTooltip>
            )}
          </div>
        </div>

        <TooltipWrapper content={infoTooltip} disabled={!infoTooltip}>
          <div data-testid={rest['data-testid-switch']} className='flex items-center justify-center'>
            <Switch
              offHandleColor='#FFFFFF'
              onHandleColor={'#FFFFFF'}
              offColor='#C3C3C3'
              onColor={switchOnColor ?? window.POSITIVE_COLOR}
              onChange={handleToggleClick}
              checked={toggleChecked}
              checkedIcon={false}
              uncheckedIcon={false}
              disabled={disabled}
              {...switchSizes[size]}
            />
          </div>
        </TooltipWrapper>
      </div>
      {children && toggleChecked && (
        <>
          <GrayGradientDivider className='w-full' />
          <div
            className={classNames('w-full', subSectionClass, {
              'p-18px': size === 'large',
              'p-3': size === 'small',
            })}
          >
            {children}
          </div>
        </>
      )}
    </div>
  );
};
