import { LAYOUT_PORTAL_ID, PAGE_HEADER_OTHER_ITEMS_WRAPPER_ID, TEST_IDS } from '@va/constants';
import { setShouldRefreshDatePicker } from '@va/dashboard/actions/ui';
import { isTabletDevice } from '@va/dashboard/selectors/ui';
import { FeatureCounter, StpCounter } from '@va/dashboard/shared/feature-counter';
import { DatePicker } from '@va/dashboard/shared/feature-date-picker';
import { RefreshIcon } from '@va/icons';
import { useTranslate } from '@va/localization';
import { PageHeaderProps } from '@va/types/page-header';
import { Button } from '@va/ui/design-system';
import { Portal, usePageHeaderContext } from '@va/util/components';
import { is3asApp } from '@va/util/helpers';
import classNames from 'classnames';
import { FC, PropsWithChildren, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutPortal } from './Layout';
import { TitlePortal } from './TopBar';

export const PageHeader = (props: PageHeaderProps) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const pageHeaderRef = useRef<HTMLDivElement>(null);
  const isTablet = useSelector(isTabletDevice);

  //Component will use the values from the context and fallback to props
  //The goal in time is to use only the context and portals where needed
  const { pageHeaderProps = {} } = usePageHeaderContext();
  const {
    title = props.title,
    hideDatePicker = props.hideDatePicker,
    dateEnabled = props.dateEnabled,
    hideFeatureCounter = props.hideFeatureCounter,
    refreshFn = props.refreshFn,
    className = props.className,
    hideBorder = props.hideBorder,
    otherItems = props.otherItems,
    renderCreateButton = props.renderCreateButton,
  } = pageHeaderProps;

  return (
    <>
      <TitlePortal>{title}</TitlePortal>
      <LayoutPortal>
        <div
          data-testid={TEST_IDS.specific.pageHeaderContainer}
          ref={pageHeaderRef}
          className={classNames(
            'z-[var(--zIndex-layout)] flex items-center bg-white backdrop-filter backdrop-blur-md justify-between order-last lg:order-first gap-2 md:gap-18px px-2 lg:pl-30 lg:pr-18 pt-18 pb-18',
            { 'lg:border-b lg:border-gray-mercury': !hideBorder },
            className,
          )}
        >
          {(props.showTitleOnTablet || !isTablet) && title}
          <div className='page-header-items w-full lg:w-auto flex items-center flex-col-reverse md:flex-row-reverse sm-initial:justify-between grow gap-3'>
            <div className='flex w-full items-center justify-end gap-2 flex-wrap other-items-wrapper'>
              <div className='flex items-center gap-2 flex-wrap' id={PAGE_HEADER_OTHER_ITEMS_WRAPPER_ID}>
                {otherItems}
              </div>
              {renderCreateButton?.()}
              {refreshFn && (
                <Button
                  data-testid={TEST_IDS.generic.buttons.refresh}
                  icon={() => <RefreshIcon />}
                  color='secondary'
                  onClick={() => {
                    dispatch(setShouldRefreshDatePicker());
                    refreshFn();
                  }}
                  tooltip={translate('card.button.refresh')}
                />
              )}
              {!hideDatePicker && <DatePicker featureReleaseDate={dateEnabled} />}
            </div>
            {!is3asApp() && !hideFeatureCounter && <FeatureCounter />}
            {is3asApp() && <StpCounter />}
          </div>
        </div>
      </LayoutPortal>
    </>
  );
};

export const PageHeaderItemsWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Portal
      elementSelector={`#${PAGE_HEADER_OTHER_ITEMS_WRAPPER_ID}`}
      mutationObserver={{ targetNode: `#${LAYOUT_PORTAL_ID}` }}
    >
      {children}
    </Portal>
  );
};
