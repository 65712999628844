import { cardView, platform } from '@va/constants';

export const storageItems = {
  userId: 'visa-login-id',
  loginToken: 'visa-login-token',
  impersonateToken: 'visa-impersonate-token',
  impersonate: 'visa-impersonate-email',
  refreshToken: 'visa-refresh-token',
  loginTokenExpiry: 'visa-login-token-expiry',
  refreshTokenExpiry: 'visa-refresh-token-expiry',
  pendingConfirmation: 'visa-pending-confirmation',
  accountType: 'visa-account-type',
  lastWebsiteId: 'last-website-id',
  recordingIds: 'visa-recordingIds',
  userEmail: 'visa-email',
  standaloneOnboarding: 'visa-standalone-onboarding',
  weeblyOnboarding: 'visa-weebly-onboarding',
  wixOnboarding: 'visa-wix-onboarding',
  addWebsite: 'visa-add-website',

  // 3AS
  clientId: 'clientId',
  companyId: 'companyId',

  // wix mini
  shortInstanceId: 'shortInstanceId',
};

export const dataViewerRoutes = [
  '/',
  '/dashboard/overview',
  '/dashboard/pages',
  '/dashboard/custom-dashboard',
  '/statistics/overview',
  '/visitors/latest',
  '/visitors/history',
  '/visitors/map',
  '/visitors/locations',
  '/visitors/charts',
  '/visitors/company-visits',
  '/pages/landing',
  '/pages/click',
  '/pages/conversions',
  '/pages/referring',
  '/devices',
  '/campaignsOverview',
  '/competition',
  '/settings',
  '/settings/tracking-code',
  '/settings/modules/event-tracking',
  '/settings/modules/recordings',
  '/settings/pages',
  '/settings/visitor-segments',
  '/settings/import',
  '/behaviour/recordings',
  '/behaviour/recordings-onboarding',
  '/behaviour/heatmaps',
  '/behaviour/funnels',
  '/behaviour/event-tracking/manual',
  '/behaviour/event-tracking',

  '/behaviour/event-tracking/auto',
  '/behaviour/event-tracking/event-tag-generator',
  '/recordings/history',
  '/campaigns/latest',
  '/campaigns/history',
  '/campaigns/my',
  '/campaigns/:campaignKey',
  '/campaigns/charts',
  '/campaigns/utm-generator',
  '/traffic/share',
  '/traffic/visits',
  '/traffic/session',
  '/traffic/bounceRate',
  '/export/general',
  '/export/history',
];

export const dataViewerRoutesNoHistory = dataViewerRoutes.filter(
  (item) => !item.includes('history') && !item.includes('details'),
);
export const dataViewerHistoryRoutes = dataViewerRoutes.filter(
  (item) => item.includes('history') || item.includes('details'),
);

export const notAllowedSubscriptionViewPlatforms = [platform.WIX, platform.KYVIO];

export const websiteStatus = {
  ACTIVE: 'ACTIVE',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export enum WebsiteStatus {
  ACTIVE = 'ACTIVE',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

export const subscriptionStatus = {
  // === Paddle ====
  TRIALING: 'Trialing',
  PAUSED: 'Paused',
  DELETED: 'Deleted',
  // === Common ====
  ACTIVE: 'Active',
  PAST_DUE: 'Past Due',
  // === Braintree ====
  PENDING: 'Pending',
  CANCELED: 'Canceled',
  EXPIRED: 'Expired',
};

export const transactionStatus = {
  // === Braintree ===
  VOIDED: 'voided', // canceled
  SUBMITTED_FOR_SETTLEMENT: 'submitted_for_settlement', // paid
  SETTLED: 'settled', // paid

  // === Paddle ===
  ACTIVE: 'active', // paid

  // === both ===
  REFUND: 'refunded',
};

export const modalName = {
  CHECK_LOGIN: 'checkLogin',
  CONFIRM_CONTRIBUTOR_REQUEST: 'confirmContributor',
  ACCEPT_CONTRIBUTOR_REQUEST: 'acceptContributorRole',
  REJECT_CONTRIBUTOR_REQUEST: 'rejectContributorRole',
  LEAVE_WEBSITE: 'leaveWebsite',
  DELETE_WEBSITE_DATA: 'deleteWebsiteData',
  CANCEL_SUBSCRIPTION: 'cancelSubscription',
  DELETE_SUBSCRIPTION: 'deleteSubscription',
  BILLING_CHECKOUT: 'billingCheckout',
  UPDATE_CARD_DETAILS: 'updateCardDetails',
  ADD_CREDIT_CARD: 'addCreditCard',
  SEND_CUSTOM_OFFER: 'sendCustomOffer',
  REACTIVATE_SUBSCRIPTION: 'reactivateSubscription',
  COMMISSION_PAYOUTS: 'commissionPayouts',
  ACCOUNT_INFO: 'accountInfo',
  BUSINESS_INFO: 'businessInfo',
  WHITE_LABEL_WEBSITE: 'whiteLabelWebsites',
  CHECK_LOGIN_MODAL: 'checkLoginModal',
  CONTRIBUTOR_ACTION_MODAL: 'contributorActionModal',
};

export const upgradeModalView = {
  INITIAL: cardView.INITIAL,
  CHECKOUT_FORM: 'checkoutForm',
  CUSTOMER_INFO_FORM: 'customerInfoForm',
  UPGRADE_CUSTOM_FORM: 'upgradeCustomForm',
  UPDATE_SUBSCRIPTION_FEEDBACK: 'updateSubscriptionFeedback',
};

export const topBarMenus = {
  ACCOUNT_SETTINGS: 'settingsMenu',
  LANGUAGE_SELECTOR: 'languageMenu',
};

export const emailAction = {
  CONFIRM: 'confirm',
  RESET: 'reset',
};

export enum EmailActionEnum {
  confirm = 'confirm',
  reset = 'reset',
}

export const BILLING_CURRENCY_SYMBOL = '$';

export const SUBSCRIPTION_PROVIDER_TYPE = {
  BRAINTREE: 'braintree',
  PADDLE: 'paddle',
};

export enum SubscriptionProviderEnum {
  braintree = 'braintree',
  paddle = 'paddle',
}

export const PAYMENT_METHOD_TYPE = {
  CARD: 'card',
  PAYPAL: 'paypal',
};

export enum PaymentMethodEnum {
  card = 'card',
  paypal = 'paypal',
}

export const FREE_PLAN_DETAILS = {
  planName: 'Free',
  visits: 400,
  recordings: 25,
  heatmaps: 1,
  polls: 1,
  surveys: 1,
};

export const APP_SUMO_PACKAGE_NAME = 'Appsumo LTD';

export const subscriptionType = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
};

export const MAX_TOP_BAR_MOBILE_VIEW_WIDTH = 500;
export const MAX_MOBILE_VIEW_WIDTH = 768;
export const MAX_TOP_BAR_UPGRADE_BADGE_WIDTH = 1280;
export const MAX_TOP_BAR_TRACKING_CODE_ERROR_WIDTH = 1566;
export const MAX_TOP_BAR_CLAIM_UPGRADE_BADGE_WIDTH_NAV_CLOSED = 1392;
export const MAX_TOP_BAR_CLAIM_UPGRADE_BADGE_WIDTH_NAV_OPEN = 1561;
export const MAX_TOP_BAR_APPLY_LIFETIME_BADGE_WIDTH = 1165;
export const MAX_TOP_BAR_APPLY_LIFETIME_BADGE_WITH_TRACKING_ERROR_WIDTH = 1365;
export const MAX_APPLIED_SUMO_VOUCHERS = 4;
export const MAX_WEBSITES_PER_PAGE = 10;

export const accountType = {
  SUMO: 'SUMO',
  WEEBLY: 'WEEBLY',
};
export const appsumoStep = {
  REGISTER: 'register',
  LOGGED_IN: 'loggedIn',
};
export const firstPromoterCookie = {
  CODE: '_fprom_code',
  TRACK: '_fprom_track',
};

export const accountSettingsTabNames = {
  manageWebsites: 'manageWebsites',
  accountSettings: 'accountSettings',
  agencyCommission: 'agencyCommission',
  agencyWhiteLabel: 'agencyWhiteLabel',
  manageBilling: 'manageBilling',
  consent: 'consent',
};

export const allowedDomainsForInAppRegister = [
  'http://localhost:3000',
  'https://dev-dashboard.va-endpoint.com',
  'https://stage-dashboard.va-endpoint.com/',
];

export const customThemeName = 'Custom';

export const agencyWhiteLabelFontFamily = [
  {
    label: 'Inter',
    value: 'Inter',
  },
  {
    label: 'Open Sans',
    value: 'Open Sans',
  },
  {
    label: 'Poppins',
    value: 'Poppins',
  },
  {
    label: 'Roboto',
    value: 'Roboto',
  },
  {
    label: 'Noto Sans',
    value: 'Noto Sans',
  },
  {
    label: 'Work Sans',
    value: 'Work Sans',
  },
];

export const commissionPayoutQuarters = ['january', 'april', 'august', 'october'];

export const MAX_BAR_CHART_ITEMS = 13;

// prettier-ignore
export const NOT_ALLOWED_CUSTOM_DOMAINS = [
  'www', 'app', 'appp', 'admin', 'login', 'free', 'register', 'webapp', 'web', 'website', 'net', 'doc', 'site',
  'dev', 'develop', 'stage', 'staging', 'local', 'localhost', 'support', 'contact', 'demo',
  'sex', 'fuck', 'shit', 'porn', 'xxx',
  'visa', 'wordpress', 'wix', 'weebly', 'drupal', 'typo3', 'joomla', 'octobercms', 'magento', 'kyvio', 'opencart', 'e-commerce',
];

export const RECORDINGS_COUNT_PRO_PLUS_PLAN = 7500;
export const RECORDINGS_COUNT_PRO_PLAN = 5000;

export const INTERN_ACCOUNT_EMAIL_ADDRESS = 'statistics@visitor-analytics.io';

export enum SubscriptionTypeEnum {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const commissionsTableHeaderPaginationId = 'commission-details-table-header-pagination';
