import { TEST_IDS, TOTAL_KEY } from '@va/constants';
import { useLocale, useTranslate } from '@va/localization';
import { CommonProps } from '@va/types/component';
import { addNumberSeparator, getPercentageColors } from '@va/util/helpers';
import { useCustomizationContext } from '@va/util/misc';
import classNames from 'classnames';
import { isNil, isNumber, round } from 'lodash';
import { FC, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Paragraph, ParagraphWithTooltip, fontWeights, paragraphSizes } from '../../typography';

export type TooltipRow = {
  color: string;
  value: number | string;
  unit?: string;
  linkTo?: string;
};

type TooltipGridRowProps = CommonProps & {
  showPrevious: boolean;
  currentValue: TooltipRow;
  previousValue: TooltipRow;
  percentageChange: number | '-';
  rowKey: string;
  reversePercentageColors?: boolean;
  valueTransformer?: (val: any) => any;
};

export function TooltipGridRow({
  showPrevious,
  currentValue,
  previousValue,
  percentageChange,
  rowKey,
  reversePercentageColors = false,
  valueTransformer,
  ...rest
}: TooltipGridRowProps) {
  const translate = useTranslate();
  const { locale } = useLocale();
  const history = useHistory();

  function formatPercentage(percentage: number | '-') {
    if (percentage === '-' || percentage === 0) return '-';
    return addNumberSeparator(round(percentage, 1), locale, {
      style: 'percent',
      signDisplay: 'exceptZero',
    });
  }

  const { getCustomValue } = useCustomizationContext();

  return (
    <>
      <div data-testid={rest['data-testid']} className='flex flex-row flex-nowrap items-center gap-2'>
        <div className='flex flex-col'>
          <div className='flex flex-row flex-nowrap items-center gap-2'>
            <div
              className='rounded-full h-2 w-2 shrink-0'
              style={{
                backgroundColor: currentValue.color,
              }}
            />
            <ParagraphWithTooltip className='leading-normal !line-clamp-2 !whitespace-normal max-w-xs'>
              {rowKey === TOTAL_KEY ? translate('text.total') : translate(rowKey)}
            </ParagraphWithTooltip>
          </div>
          {getCustomValue('showLinks', true) && currentValue?.linkTo && (
            <Paragraph
              data-testid={TEST_IDS.generic.link}
              size={paragraphSizes.tiny}
              weight={fontWeights.semibold}
              className='text-green-pure cursor-pointer'
              onClick={() => history.push(currentValue.linkTo as string)}
            >
              {translate('tooltip.label.moreDetails')}
            </Paragraph>
          )}
        </div>
      </div>
      <TooltipValue className='h-full' {...currentValue} valueTransformer={valueTransformer} />

      {showPrevious && !isNil(previousValue) && (
        <TooltipValue className='h-full' {...previousValue} valueTransformer={valueTransformer} />
      )}
      <div
        className={classNames(
          'text-right h-full',
          getPercentageColors(percentageChange === '-' ? 0 : percentageChange, reversePercentageColors),
        )}
      >
        {formatPercentage(percentageChange)}
      </div>
    </>
  );
}

const TooltipValue: FC<{
  value: number | string;
  unit?: string;
  className?: string;
  valueTransformer?: (val: any) => any;
}> = ({ value, unit, className, valueTransformer }) => {
  const { locale } = useLocale();

  const val = useMemo(() => {
    if (valueTransformer) {
      return valueTransformer(value);
    }

    if (isNumber(value)) {
      return addNumberSeparator(value, locale);
    }

    return `${value} ${unit}`;
  }, [locale, unit, value, valueTransformer]);

  return <div className={classNames('whitespace-nowrap', className)}>{val}</div>;
};
