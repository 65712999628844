import { Filter } from '@va/types/filters';

type AppliedFilterProps = {
  filter: Filter;
  disableEditing?: boolean;
  viewOnly?: boolean;
  size?: 'small' | 'medium' | 'large';
};

export const AppliedFilter = (props: AppliedFilterProps) => {
  const AppliedFilterComponent = props.filter.appliedInput;
  return <AppliedFilterComponent {...props} {...props.filter.inputProps} />;
};
