import { RootState } from '@va/wix-mini/shared/types';
import { Website } from '@visitor-analytics/3as-sdk';

type IntpWebsite = Website & { intpName: string };

export const isInitialized = (state: RootState) => state.getIn(['app'])?.initialized as boolean;

export const getWixInstanceId = (state: RootState) => state.getIn(['app'])?.instanceId as string | undefined;

export const selectIntpWebsite = (state: RootState) => (state.getIn(['app'])?.intpWebsite as IntpWebsite) ?? {};

export const selectSessionRecordingsLimit = (state: RootState) => {
  const intpWebsite = state.getIn(['app'])?.intpWebsite as IntpWebsite | undefined;

  if (!intpWebsite?.consumption) return undefined;
  if (intpWebsite.consumption.stpLimit === 0 && intpWebsite.consumption.stpRateSessionRecording === 0) return undefined;

  return intpWebsite.consumption.stpLimit / intpWebsite.consumption.stpRateSessionRecording;
};

export const selectSessionRecordingsConsumed = (state: RootState) => {
  const intpWebsite = state.getIn(['app'])?.intpWebsite as IntpWebsite | undefined;

  return intpWebsite?.consumption?.stpConsumed ?? 0;
};

export const isSessionRecordingsLimitReached = (state: RootState) => {
  const intpWebsite = state.getIn(['app'])?.intpWebsite as IntpWebsite | undefined;

  if (!intpWebsite?.consumption) return false;

  return intpWebsite.consumption.stpConsumed >= intpWebsite.consumption.stpLimit;
};

export const selectCurrentPlanName = (state: RootState) => {
  const intpWebsite = state.getIn(['app'])?.intpWebsite as IntpWebsite | undefined;

  return intpWebsite?.packageName;
};

export const getPredefinedFilters = (state: RootState) => {
  return state.get('app').predefinedFilters;
};
