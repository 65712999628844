import { TEST_IDS } from '@va/constants';
import { useLocale, useTranslate } from '@va/localization';
import { ButtonLoader } from '@va/svg-visa-icons';
import { fontWeights, Paragraph, paragraphSizes } from '@va/ui/design-system';
import { addNumberSeparator, computePercentage } from '@va/util/helpers';
import classNames from 'classnames';
import { useMemo } from 'react';

type CounterProps = {
  consumed: number;
  total: number;
  label: string;
  className?: string;
  hasInfiniteLimit?: boolean;
  circularProgressBar?: boolean;
  size?: 'small' | 'medium';
};

export function Counter({
  className,
  consumed = 0,
  total = 100,
  label,
  hasInfiniteLimit,
  circularProgressBar,
  size = 'medium',
}: CounterProps) {
  const translate = useTranslate();
  const { locale } = useLocale();

  const remaining = useMemo(() => {
    const left = total - consumed;
    if (left < 0) return 0;
    return left;
  }, [total, consumed]);

  const progressBarWidth = useMemo(() => {
    if (consumed === 0) return 0;
    return Number.parseFloat(computePercentage(consumed, total));
  }, [consumed, total]);

  const limit = useMemo(() => {
    if (hasInfiniteLimit) return '∞';

    if (total === -1) return addNumberSeparator(consumed, locale);
    if (total === 0) {
      return <ButtonLoader color='var(--color-primary)' />;
    }
    return translate('feature.counter.counting', {
      behaviourCount: addNumberSeparator(remaining, locale),
      featureLimit: addNumberSeparator(total, locale),
    });
  }, [hasInfiniteLimit, total, consumed, locale, translate, remaining]);

  const wrapperClasses = classNames('flex cursor-pointer', className, {
    'flex-col lg:min-w-[270px]': !circularProgressBar,
    'gap-9px items-center': circularProgressBar,
  });

  return (
    <div data-testid={TEST_IDS.specific.featureCounter.btn} className={wrapperClasses}>
      <div
        className={classNames('flex items-center flex-row flex-nowrap gap-1', {
          'justify-between': !circularProgressBar,
          'justify-end': circularProgressBar,
        })}
      >
        <div className={classNames('flex xs:flex-row md:flex-col items-center md:items-start truncate')}>
          <Paragraph className='truncate' size={paragraphSizes.tiny} weight={fontWeights.semibold}>
            {circularProgressBar ? limit : label}
          </Paragraph>

          <Paragraph
            size={paragraphSizes.extraTiny}
            colorClassName='text-primary'
            weight={fontWeights.medium}
            className={'whitespace-nowrap'}
          >
            {translate('label.moreDetails')}
          </Paragraph>
        </div>
        {!circularProgressBar && (
          <Paragraph
            colorClassName={classNames(
              { 'text-gray-charcoal': !hasInfiniteLimit },
              { 'text-orange-dark': hasInfiniteLimit },
            )}
            size={paragraphSizes.tiny}
            weight={fontWeights.semibold}
            className='flex items-center whitespace-pre'
          >
            {limit}
          </Paragraph>
        )}
      </div>
      {circularProgressBar && (
        <div
          className='h-6 w-6 rounded-full'
          style={{
            background: `radial-gradient(closest-side, white 60%, transparent 20% 100%), conic-gradient(var(--color-primary) ${
              (consumed * 100) / total
            }%, var(--color-primary-lighter-20) 0)`,
          }}
        />
      )}

      {!circularProgressBar && (
        <div className='mt-2 h-2 rounded-sm relative'>
          {/* Progress bar */}
          <div
            className='absolute bg-orange-dark h-full z-1 top-0 lg:max-w-inherit max-w-full'
            style={{
              left: 0,
              width: `${progressBarWidth}%`,
            }}
          />
          {/* Background bar */}
          <div className='absolute bg-gray-concrete w-full h-full inset-0 z-0 lg:max-w-inherit' />
        </div>
      )}
    </div>
  );
}
