import { DeviceTypesEnum } from '@va/types/device';

import { useCurrentPeriodFilter, usePreviousPeriodFilter } from '@va/dashboard/util-hooks';
import { get, post } from '@va/http-client';
import { calculatePercentFromTotal, getAlpha2CountryCodes, toQueryString } from '@va/util/helpers';
import { useFetchData, useLazyDataFetch } from '@va/util/hooks';

import { getInstanceId } from '@va/dashboard/selectors/app';
import { getFillColor, MapData } from '@va/ui/components/maps';
import { Moment } from 'moment';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export type VisitorDataType = {
  countryCode: string;
  ip: string;
  visitorType: number;
  latitude: number;
  longitude: number;
  deviceType: string;
  visitorKey: string;
  platform: string;
  browserName: string;
  visitDetails: string;
  lastVisitTs: number;
  sessionKey: string;
};

export type LiveVisitorsMapDataType = MapData<{ visitors: VisitorDataType[]; count: number }>;

export type Meta = {
  page: number;
  pageSize: number;
  total: number;
};

export type PayloadItem = {
  count: number;
  previousCount?: number;
};

export type VisitsResponse = {
  meta: {
    pageVisits: number;
    previousPageVisits?: number;
  } & Meta;
  payload: Array<
    {
      id: string;
      suffix: string;
      title: string;
      url: string;
    } & PayloadItem
  >;
};

export type VisitorsResponse = {
  meta: {
    visitorsTotal: number;
    previousVisitorsTotal?: number;
  } & Meta;
  payload: Array<
    {
      [key in 'device' | 'browser' | 'display' | 'operatingSystem']: string;
    } & PayloadItem
  >;
};

type SessionByCountryPayloadItem = { country: string } & PayloadItem;

export type SessionsByCountryResponse = {
  meta: {
    sessionsTotal: number;
    previousSessionsTotal?: number;
  } & Meta;
  payload: Array<SessionByCountryPayloadItem>;
};

export type Query = {
  page: number;
  pageSize: number;
  group?: string;
  order?: string;
};

export const getSessionInfo = (websiteId: string, id: string) => {
  const url = `/websites/${websiteId}/sessions/${id}/info`;
  return get(url, {});
};

export const getLatestVisitorsMap = (websiteId: string, filter: { from: Moment; until: Moment }) => {
  const url = `/websites/${websiteId}/visitors/map`;

  // api endpoint requires only from & until query params from filter
  const newFilter = {
    from: filter.from,
    until: filter.until,
  };

  return get(url, newFilter);
};

export const getLatestVisitorsList = (
  websiteId: string,
  queryData: Record<string, unknown>,
  payloadData: Record<string, unknown>,
) => {
  const url = `/websites/${websiteId}/sessions/table`;
  return post(url, queryData, payloadData);
};

export const getLatestVisitorsOss = (websiteId: string) => {
  const url = `/websites/${websiteId}/sessions/oss`;
  return get(url, {});
};

export const getLatestVisitorsBrowsers = (websiteId: string) => {
  const url = `/websites/${websiteId}/sessions/browsers`;
  return get(url, {});
};

export const useVisitors = <T>(query: Query, mapper?: (x: VisitorsResponse) => Array<T>) => {
  const { from, until, websiteId } = useCurrentPeriodFilter(false);
  const { from: previousFrom, until: previousUntil } = usePreviousPeriodFilter(false);
  const queryData = useMemo(
    () => ({ from, until, previousFrom, previousUntil, ...query }),
    [from, previousFrom, previousUntil, query, until],
  );

  const url = useMemo(() => `/v2/websites/${websiteId}/visitors?${toQueryString(queryData)}`, [queryData, websiteId]);

  return useLazyDataFetch<Array<T>, Error>(url, undefined, mapper);
};

export type MappedSessionByCountry = Omit<SessionByCountryPayloadItem, 'country'> & {
  percent: number;
  countryCode: string;
};

export const useSessionsByCountryList = (query: Query) => {
  const { from, until, websiteId } = useCurrentPeriodFilter(false);
  const { from: previousFrom, until: previousUntil } = usePreviousPeriodFilter(false);

  const queryData = useMemo(
    () => ({
      from,
      until,
      previousFrom: previousFrom,
      previousUntil: previousUntil,
      group: 'country',
      ...query,
    }),
    [from, previousFrom, previousUntil, query, until],
  );

  const url = useMemo(() => `/v2/websites/${websiteId}/sessions?${toQueryString(queryData)}`, [queryData, websiteId]);

  const mapper = useCallback((response: SessionsByCountryResponse) => {
    const {
      meta: { sessionsTotal },
      payload,
    } = response;

    return payload.map(({ country, count, previousCount }) => ({
      countryCode: country,
      count,
      percent: calculatePercentFromTotal(count, sessionsTotal),
      previousCount: previousCount ?? 0,
    }));
  }, []);

  return useLazyDataFetch<Array<MappedSessionByCountry>>(url, undefined, mapper);
};

type LiveVisitor = {
  browserName: string;
  countryCode: string;
  deviceType: string;
  ip: string;
  platform: string;
  visitDetails: string;
  visitorKey: string;
  lastVisitTs: number;
  latitude: number;
  longitude: number;
  visitorType: number;
  sessionKey: string;
};

export type LiveVisitorsResponse = {
  count: number;
  visitors: Array<LiveVisitor>;
};

export type MappedLiveVisitorsResponse = {
  count: number;
  visitors: LiveVisitorsMapDataType;
};
type VisitorsCountType = {
  [countryCode: string]: number;
};

export const useLiveVisitors = () => {
  const { from, until, websiteId } = useCurrentPeriodFilter(true);

  const queryData = { from, until };

  const mapperFn = useCallback((response: LiveVisitorsResponse) => {
    const { visitors, count } = response;
    const alpha2CountryCodes = getAlpha2CountryCodes();
    const visitorsCount: VisitorsCountType = {} as VisitorsCountType;
    const formattedData = {} as LiveVisitorsMapDataType;
    visitors.forEach((visitor) => {
      const { countryCode } = visitor;
      const alpha3countryCode = alpha2CountryCodes[countryCode];
      if (alpha3countryCode in formattedData) {
        const aux = formattedData[alpha3countryCode];

        aux.visitors.push({ ...visitor });
        if (aux?.count) {
          aux.count += 1;
        }

        visitorsCount[alpha3countryCode] = visitorsCount[alpha3countryCode] + 1;
      } else {
        formattedData[alpha3countryCode] = {
          fillColor: 'rgba(92, 4, 180,0.1)',
          visitors: [{ ...visitor }],
          count: 1,
        };
        visitorsCount[alpha3countryCode] = 1;
      }
    });

    for (const countryKey in formattedData) {
      formattedData[countryKey].fillColor = getFillColor(visitorsCount[countryKey]);
    }

    return { visitors: formattedData, count };
  }, []);

  return useLazyDataFetch<MappedLiveVisitorsResponse, Error>(
    `/websites/${websiteId}/overview/live-visitors?${toQueryString(queryData)}`,
    undefined,
    mapperFn,
  );
};

export type VisitSession = {
  id: string;
  status: number;
  sessionStart: number;
  ip: string;
  countryCode: string;
  location: {
    lat: number;
    lng: number;
  };
  adCampaignKey: string | null;
  adCampaignSource: string | null;
  adCampaignMedium: string | null;
  adCampaignTerm: string | null;
  adCampaignContent: string | null;
  referrer: string;
  hasRecording: boolean;
  duration: number;
  adCampaignLabel: string;
  pageVisits: {
    key: string;
    url: string;
    title: string;
    unixTs: number;
    privacyLevel: number;
  }[];
  browser: string;
  platform: string;
  device: DeviceTypesEnum;
  display: string;
};

export type VisitorHistoryResponse = {
  data: {
    ip: string;
    device: DeviceTypesEnum;
    platform: string;
    browser: string;
    countryCode: string;
    display: string;
    location: {
      lat: number;
      lng: number;
    };
    sessions: VisitSession[];
    sessionExtras: {
      id: string;
      seen: boolean;
      star: boolean;
    };
  };
};

export const useVisitorsHistory = (sessionKey: string) => {
  const websiteId = useSelector(getInstanceId);
  const queryData = {
    sessionId: sessionKey,
  };

  const mapperFn = useCallback((response: VisitorHistoryResponse) => {
    const {
      data: { sessions, browser, device, platform, display },
    } = response;
    return sessions.map((session) => {
      return { ...session, browser, device, platform, display };
    });
  }, []);

  return useFetchData<VisitSession[], Error>(
    `/websites/${websiteId}/sessions/log?${toQueryString(queryData)}`,
    undefined,
    mapperFn,
  );
};
