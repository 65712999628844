import { Button, ButtonShapes } from '@va/ui/design-system';
import { DropdownArrow, ModalWrapper } from '@va/util/components';
import { areArraysEqual } from '@va/util/helpers';
import classNames from 'classnames';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import React from 'react';
import Calendar from './component/Calendar';
import Header from './component/Header';
import Month from './component/Month';
import Shortcut from './component/Shortcut';
import Week from './component/Week';
import Year from './component/Year';
import {
  dateObjToString,
  defaultShortcuts,
  getBetweenRange,
  getCurrentDate,
  getDateRange,
  getDisableDate,
  getFormatedDate,
  getNextDate,
  getPreviousDate,
  getToValueFromArray,
  getToValueFromString,
  isArray,
  isObject,
  sortDates,
} from './lib/fn';

import { TEST_IDS } from '@va/constants';
import { Clock } from '@va/icons';
import { TooltipWrapper } from '@va/ui/tooltips';
import './style.css';

dayjs.extend(localeData);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(duration);

class LitepieDatepicker extends React.Component {
  constructor(props) {
    super(props);
    this.prefixValue = false;
    const panelData = {
      previous: { calendar: true, month: false, year: false },
      next: { calendar: true, month: false, year: false },
    };
    const datepickerData = {
      previous: dayjs(),
      next: dayjs().add(1, 'month'),
      year: {
        previous: dayjs().year(),
        next: dayjs().year(),
      },
      weeks: dayjs.weekdaysShort(),
      months: this.props.formatter.month === 'MMM' ? dayjs.monthsShort() : dayjs.months(),
    };

    this.state = {
      disableInRange: true,
      isShow: false,
      placement: true,
      givenPlaceholder: '',
      selection: null,
      pickerValue: '',
      hoverValue: [],
      applyValue: [],
      previous: null,
      next: null,
      panel: panelData,
      datepicker: datepickerData,

      prevmonth: false,
      prevyear: false,
      nextmonth: false,
      nextyear: false,

      modelValue: [...props.initialValues],
    };
  }

  refresh = async () => {
    this.setInitialValues();
    await this.setLocale();
    this.setPlaceholder();
    this.updateDatePrefix();
  };

  componentDidMount() {
    this.refresh();
  }

  setPlaceholder = () => {
    if (!this.props.placeholder) {
      if (this.isRange()) {
        this.setState({
          givenPlaceholder: `${this.props.formatter.date}${this.props.separator}${this.props.formatter.date}`,
        });
      } else {
        this.setState({
          givenPlaceholder: this.props.formatter.date,
        });
      }
    } else {
      this.setState({
        givenPlaceholder: this.props.placeholder,
      });
    }
  };

  setInitialValues = () => {
    const { initialValues, separator, formatter } = this.props;
    initialValues.length > 0 &&
      this.setState({
        modelValue: initialValues.length === 1 ? initialValues[0] : initialValues,
        pickerValue:
          initialValues.length === 1
            ? initialValues[0]
            : dateObjToString(initialValues, formatter.date).join(` ${separator} `),
        applyValue: initialValues,
      });
  };

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setInputRef = (node) => {
    this.inputRef = node;
  };

  async componentDidUpdate(prevProps, prevState) {
    const { datepicker, modelValue, pickerValue } = this.state;
    if (prevProps.shouldRefreshDatePicker !== this.props.shouldRefreshDatePicker) {
      this.refreshDatepicker();
    }
    if (prevState.pickerValue !== pickerValue) {
      this.updateDatePrefix();
    }
    if (!areArraysEqual(prevProps.initialValues, this.props.initialValues)) {
      this.refresh();
    }
    if (prevProps.i18n !== this.props.i18n || prevProps.minDate !== this.props.minDate) {
      this.refresh();
    }

    if (!areArraysEqual(prevState.modelValue, modelValue)) {
      const sortedDates = sortDates(modelValue);
      this.props.updatedValues(sortedDates);
    }

    if (prevState.applyValue !== this.state.applyValue && this.state.applyValue.length > 0) {
      this.setState((currentState) => ({
        panel: {
          ...currentState.panel,
          previous: { calendar: true, month: false, year: false },
          next: { calendar: true, month: false, year: false },
        },
      }));
    }

    if (prevState.prevmonth !== this.state.prevmonth) {
      if (datepicker.next.isSame(datepicker.previous, 'month') || datepicker.next.isBefore(datepicker.previous)) {
        this.setState((currentState) => ({
          datepicker: {
            ...currentState.datepicker,
            next: currentState.datepicker.previous.add(1, 'month'),
          },
        }));
      }
    }

    if (prevState.prevyear !== this.state.prevyear) {
      if (datepicker.next.isSame(datepicker.previous, 'month') || datepicker.next.isBefore(datepicker.previous)) {
        this.setState((currentState) => ({
          datepicker: {
            ...currentState.datepicker,
            next: currentState.datepicker.previous.add(1, 'month'),
          },
        }));
      }
    }

    if (prevState.nextmonth !== this.state.nextmonth) {
      if (datepicker.previous.isSame(datepicker.next, 'month') || datepicker.previous.isAfter(datepicker.next)) {
        this.setState((currentState) => ({
          datepicker: {
            ...currentState.datepicker,
            previous: datepicker.next.subtract(1, 'month'),
          },
        }));
      }
    }

    if (prevState.nextyear !== this.state.nextyear) {
      if (datepicker.previous.isSame(datepicker.next, 'month') || datepicker.previous.isAfter(datepicker.next)) {
        this.setState((currentState) => ({
          datepicker: {
            ...currentState.datepicker,
            previous: datepicker.next.subtract(1, 'month'),
          },
        }));
      }
    }
  }

  setLocale = async () => {
    /* @vite-ignore */
    await import(`dayjs/locale/${this.props.i18n}.js`)
      .then((locale) => {
        dayjs.locale(locale);
        let startedDate, endedDate;
        if (this.isRange()) {
          if (isArray(this.state.modelValue)) {
            if (this.state.modelValue.length > 0) {
              const [start, end] = this.state.modelValue;
              startedDate = dayjs(start);
              endedDate = dayjs(end);
            }
          } else if (isObject(this.state.modelValue)) {
            if (this.state.modelValue) {
              const [start, end] = Object.values(this.state.modelValue);
              startedDate = start && dayjs(start);
              endedDate = end && dayjs(end);
            }
          } else {
            if (this.state.modelValue) {
              const [start, end] = this.state.modelValue.split(this.props.separator);
              startedDate = dayjs(start);
              endedDate = dayjs(end);
            }
          }

          if (startedDate && endedDate) {
            this.setState({
              pickerValue: getToValueFromArray({ previous: startedDate, next: endedDate }, this.props),
            });
            if (endedDate.isBefore(startedDate, 'month')) {
              this.setState((currentState) => ({
                datepicker: {
                  ...currentState.datepicker,
                  previous: endedDate,
                  next: startedDate,
                  year: {
                    previous: endedDate.year(),
                    next: startedDate.year(),
                  },
                },
              }));
            } else if (endedDate.isSame(startedDate, 'month')) {
              this.setState((currentState) => ({
                datepicker: {
                  ...currentState.datepicker,
                  previous: startedDate,
                  next: endedDate.add(1, 'month'),
                  year: {
                    previous: startedDate.year(),
                    next: startedDate.add(1, 'year').year(),
                  },
                },
              }));
            } else {
              this.setState((currentState) => ({
                datepicker: {
                  ...currentState.datepicker,
                  previous: startedDate,
                  next: endedDate,
                  year: {
                    previous: startedDate.year(),
                    next: endedDate.year(),
                  },
                },
              }));
            }
            if (!this.props.autoApply) {
              this.setState({
                applyValue: [startedDate, endedDate],
              });
            }
          } else {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                previous: dayjs(this.props.startFrom),
                next: dayjs(this.props.startFrom).add(1, 'month'),
                year: {
                  previous: this.state.datepicker.previous.year(),
                  next: this.state.datepicker.next.year(),
                },
              },
            }));
          }
        } else {
          if (isArray(this.state.modelValue)) {
            if (this.state.modelValue.length > 0) {
              const [start] = this.state.modelValue;
              startedDate = dayjs(start);
            }
          } else if (isObject(this.state.modelValue)) {
            if (this.state.modelValue) {
              const [start] = Object.values(this.state.modelValue);
              startedDate = dayjs(start);
            }
          } else {
            if (this.state.modelValue.length) {
              const [start] = this.state.modelValue.split(this.props.separator);
              startedDate = dayjs(start);
            }
          }

          if (startedDate && startedDate.isValid()) {
            this.setState((currentState) => ({
              pickerValue: getToValueFromString(startedDate, this.props),
              datepicker: {
                ...currentState.datepicker,
                previous: startedDate,
                next: startedDate.add(1, 'month'),
                year: {
                  previous: startedDate.year(),
                  next: startedDate.add(1, 'year').year(),
                },
              },
            }));
            if (!this.props.autoApply) {
              this.setState({
                applyValue: [startedDate],
              });
            }
          } else {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                previous: dayjs(this.props.startFrom),
                next: dayjs(this.props.startFrom).add(1, 'month'),
                year: {
                  previous: currentState.datepicker.previous.year(),
                  next: currentState.datepicker.next.year(),
                },
              },
            }));
          }
        }
        this.setInitialValues();
        this.setState((currentState) => ({
          datepicker: {
            ...currentState.datepicker,
            weeks: dayjs.weekdaysShort(),
            months: this.props.formatter.month === 'MMM' ? dayjs.monthsShort() : dayjs.months(),
          },
        }));
      })
      .catch(() => undefined);
  };

  show = () => {
    this.setState({ isShow: true });
  };

  hide = () => {
    this.setState({ isShow: false });
  };

  weeks() {
    return this.state.datepicker.weeks;
  }

  months() {
    return this.state.datepicker.months;
  }

  forceEmit = (startedDate, endedDate) => {
    const { datepicker } = this.state;
    const nextDate = dayjs(endedDate);
    this.setState((currentState) => ({
      datepicker: {
        ...currentState.datepicker,
        previous: dayjs(startedDate),
        next: nextDate,
      },
    }));
    if (
      dayjs.duration(datepicker.next.diff(datepicker.previous)).$d.months === 2 ||
      (dayjs.duration(datepicker.next.diff(datepicker.previous)).$d.months === 1 &&
        dayjs.duration(datepicker.next.diff(datepicker.previous)).$d.days === 7)
    ) {
      this.setState((currentState) => ({
        datepicker: {
          ...currentState.datepicker,
          next: currentState.datepicker.next.subtract(1, 'month'),
        },
      }));
    }
    if (nextDate.isSame(dayjs(startedDate), 'month') || nextDate.isBefore(dayjs(startedDate))) {
      this.setState((currentState) => ({
        datepicker: {
          ...currentState.datepicker,
          next: dayjs(startedDate).add(1, 'month'),
        },
      }));
    }
  };

  emitShortcut = (start, end) => {
    this.force();
    const [startedDate, endedDate] = this.checkLimit(start, end);

    if (this.isRange()) {
      if (this.props.autoApply) {
        if (isArray(this.state.modelValue)) {
          this.setState({ modelValue: [startedDate, endedDate] });
        } else if (isObject(this.state.modelValue)) {
          const obj = {};
          const [start, end] = Object.keys(this.state.modelValue);
          obj[start] = startedDate;
          obj[end] = endedDate;
          this.setState({
            modelValue: obj,
          });
        } else {
          this.setState({
            modelValue: getToValueFromArray({ previous: startedDate, next: endedDate }, this.props),
          });
        }
        this.setState({
          pickerValue: getToValueFromArray({ previous: startedDate, next: endedDate }, this.props),
        });
      } else {
        this.setState({
          applyValue: [dayjs(startedDate), dayjs(endedDate)],
        });
      }
    } else {
      if (this.props.autoApply) {
        if (isArray(this.state.modelValue)) {
          this.setState({ modelValue: [startedDate] });
        } else if (isObject(this.state.modelValue)) {
          const obj = {};
          const [start] = Object.keys(this.state.modelValue);
          obj[start] = startedDate;
          this.setState({ modelValue: obj });
        } else {
          this.setState({ modelValue: startedDate });
        }
        this.setState({
          picker: startedDate,
        });
      } else {
        this.setState({
          applyValue: [dayjs(startedDate), dayjs(endedDate)],
        });
      }
    }
    this.forceEmit(startedDate, endedDate);
  };

  disableDateFunc = (date) => {
    const { minDate, maxDate } = this.props;
    const minimumDate = minDate && dayjs(minDate).startOf('day');
    const min = date.valueOf() < minimumDate;
    const max = !!maxDate && date.valueOf() > new Date(maxDate).valueOf();
    return min || max;
  };

  checkifDisable = (item, diff) => {
    const { minDate } = this.props;
    const { start } = getDateRange(item, diff, this.props.minDate);
    return minDate && start.valueOf() < dayjs(minDate).startOf('day');
  };

  checkIfSelected = (item, diff) => {
    const { start, end } = getDateRange(item, diff, this.props.minDate);
    const [started, ended] = sortDates(this.state.applyValue);
    const finalValue = started && ended && started.valueOf() === start.valueOf() && ended.valueOf() === end.valueOf();
    return finalValue;
  };

  setToDate = (item, diff) => {
    const { start, end } = getDateRange(item, diff, this.props.minDate);
    this.emitShortcut(start, end);
  };

  getFinalDate = (date) => {
    const { minDate, maxDate } = this.props;

    date = date.valueOf() < minDate.valueOf() ? getFormatedDate(minDate) : date;
    date = date.valueOf() > maxDate.valueOf() ? getFormatedDate(maxDate) : date;
    return date;
  };

  checkLimit = (startedDate, endedDate) => {
    startedDate = this.getFinalDate(startedDate);
    endedDate = this.getFinalDate(endedDate);
    return [startedDate, endedDate];
  };

  setDate = (selectedDate, asNext) => {
    const date = dayjs(new Date(selectedDate)).tz(window.timezone);
    const { previous, pickerValue, datepicker, applyValue } = this.state;
    if (this.isRange()) {
      if (previous) {
        this.setState({
          next: date,
        });
        if (this.props.autoApply) {
          if (date.isBefore(previous)) {
            this.setState({
              pickerValue: getToValueFromArray({ previous: date, next: previous }, this.props),
            });
          } else {
            this.setState({
              pickerValue: getToValueFromArray({ previous: previous, next: date }, this.props),
            });
          }
          const [startedDate, endedDate] = getToValueFromArray({ previous: previous, next: date }, this.props).split(
            this.props.separator,
          );

          if (isArray(this.state.modelValue)) {
            this.setState({
              modelValue: [dayjs(startedDate), dayjs(endedDate)],
            });
          } else if (isObject(this.state.modelValue)) {
            const obj = {};
            const [start, end] = Object.keys(this.state.modelValue);
            obj[start] = startedDate;
            obj[end] = endedDate;
            this.setState({
              modelValue: obj,
            });
          } else {
            this.setState({
              modelValue: [dayjs(startedDate), dayjs(endedDate)],
            });
          }
          this.setState({
            isShow: false,
            applyValue: [],
          });
          if (!dayjs(startedDate).isSame(dayjs(endedDate), 'month')) {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                previous: dayjs(startedDate),
                next: dayjs(endedDate),
              },
            }));
          }
          this.force();
        } else {
          if (previous.isAfter(date, 'month')) {
            this.setState({
              applyValue: [date, previous],
            });
          } else {
            this.setState({
              applyValue: [previous, date],
            });
          }

          if (!previous.isSame(date, 'month')) {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                previous: previous,
                next: date,
              },
            }));
          }
          this.force();
        }
      } else {
        const [, oldNext] = applyValue;
        const newHoverValue = [...this.state.hoverValue];
        newHoverValue.push(date);
        this.setState({ applyValue: [] });
        this.setState({
          previous: date,
          selection: date,
          hoverValue: newHoverValue,
          applyValue: [date, oldNext],
        });
        if (asNext) {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              next: date,
            },
          }));
          if (datepicker.previous.isSame(date, 'month')) {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                next: date.add(1, 'month'),
              },
            }));
          }
        } else {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              previous: date,
            },
          }));
          if (datepicker.next.isSame(date, 'month')) {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                previous: datepicker.next,
                next: date.add(1, 'month'),
              },
            }));
          }
        }
      }
    } else {
      if (this.props.autoApply) {
        this.setState({
          pickerValue: getToValueFromString(date, this.props),
        });
        if (isArray(this.state.modelValue)) {
          this.setState({
            modelValue: [getToValueFromString(date, this.props)],
          });
        } else if (isObject(this.state.modelValue)) {
          const obj = {};
          const [start] = Object.keys(this.state.modelValue);
          obj[start] = pickerValue;
          this.setState({ modelValue: obj });
        } else {
          this.setState({ modelValue: getToValueFromString(date, this.props) });
        }
        this.setState({
          isShow: false,
          applyValue: [],
        });
        this.force();
      } else {
        this.setState({
          applyValue: [date],
        });
        this.force();
      }
    }
  };

  force = () => {
    this.setState({
      previous: null,
      next: null,
      hoverValue: [],
      selection: null,
    });
  };

  inRangeDate = (date) => {
    const { pickerValue } = this.state;
    if (this.state.disableInRange) return false;
    if (pickerValue === '') return false;
    let startedDate, endedDate;
    if (isArray(this.state.modelValue)) {
      const [start, end] = this.state.modelValue;
      startedDate = start;
      endedDate = end;
    } else if (isObject(this.state.modelValue)) {
      if (this.state.modelValue) {
        const [start, end] = Object.values(this.state.modelValue);
        startedDate = start;
        endedDate = end;
      }
    } else {
      const [start, end] = this.state.modelValue.split(this.props.separator);
      startedDate = start;
      endedDate = end;
    }

    return date.isBetween(dayjs(startedDate), dayjs(endedDate), 'date', '[]');
  };

  isRange = () => {
    if (!this.props.useRange && !this.props.asSingle) {
      return true;
    } else if (!this.props.useRange && this.props.asSingle) {
      return false;
    } else if (this.props.useRange && !this.props.asSingle) {
      return true;
    } else return !!(this.props.useRange && this.props.asSingle);
  };

  isBetweenRange = (date) => {
    const { previous, hoverValue, applyValue } = this.state;
    if (previous && this.props.autoApply) return false;
    let startedDate, endedDate;
    if (hoverValue.length > 1) {
      const [start, end] = hoverValue;
      startedDate = dayjs(start);
      endedDate = dayjs(end);
    } else {
      if (isArray(this.state.modelValue)) {
        if (this.props.autoApply) {
          const [start, end] = this.state.modelValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          if (applyValue.length > 0) {
            const [start, end] = applyValue;
            startedDate = dayjs(start);
            endedDate = dayjs(end);
          }
        }
      } else if (isObject(this.state.modelValue)) {
        if (this.props.autoApply) {
          if (this.state.modelValue) {
            const [start, end] = Object.values(this.state.modelValue);
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          }
        } else {
          const [start, end] = applyValue;
          startedDate = dayjs(start);
          endedDate = dayjs(end);
        }
      } else {
        if (this.props.autoApply) {
          const [start, end] = this.state.modelValue
            ? this.state.modelValue.split(this.props.separator)
            : [false, false];
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          const [start, end] = applyValue;
          startedDate = dayjs(start);
          endedDate = dayjs(end);
        }
      }
    }
    if (startedDate && endedDate) {
      return getBetweenRange(date, {
        previous: startedDate,
        next: endedDate,
      });
    }
    return false;
  };

  calendar = () => {
    const { hoverValue, panel, datepicker } = this.state;

    return {
      previous: {
        date: () => {
          return getPreviousDate(datepicker.previous)
            .concat(getCurrentDate(datepicker.previous))
            .concat(getNextDate(datepicker.previous))
            .map((v) => {
              v.today = v.isToday();
              v.active = datepicker.previous.month() === v.month();
              v.off = datepicker.previous.month() !== v.month();
              v.sunday = v.day() === 0;
              v.disabled = getDisableDate(v, this.disableDateFunc) && !v.isToday() && !this.inRangeDate(v);
              v.inRange = () => {
                if (this.props.asSingle && !this.props.useRange) {
                  return datepicker.previous.month() !== v.month();
                }
              };
              v.hovered = () => {
                if (!this.isRange()) return false;
                if (hoverValue.length > 1) {
                  return (
                    (v.isBetween(hoverValue[0], hoverValue[1], 'date', '()') ||
                      v.isBetween(hoverValue[1], hoverValue[0], 'date', '()')) &&
                    datepicker.previous.month() === v.month()
                  );
                }
                return false;
              };
              v.duration = () => {
                return false;
              };
              return v;
            });
        },
        month: datepicker.previous && datepicker.previous.format(this.props.formatter.month),
        year: datepicker.previous && datepicker.previous.year(),
        years: () => {
          return Array.from(
            {
              length: 12,
            },
            (v, k) => {
              return datepicker.year.previous + k;
            },
          );
        },
        onPrevious: () => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              previous: currentState.datepicker.previous.subtract(1, 'month'),
            },
          }));
        },
        onNext: () => {
          const nextData =
            this.state.datepicker.previous.diff(this.state.datepicker.next, 'month') === -1 ||
            this.state.datepicker.previous.diff(this.state.datepicker.next, 'month') === 0
              ? this.state.datepicker.next.add(1, 'month')
              : this.state.datepicker.next;
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              previous: currentState.datepicker.previous.add(1, 'month'),
              next: nextData,
            },
          }));
        },
        onPreviousYear: () => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              year: {
                ...currentState.datepicker.year,
                previous: currentState.datepicker.year.previous - 12,
              },
            },
          }));
        },
        onNextYear: () => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              year: {
                ...currentState.datepicker.year,
                previous: currentState.datepicker.year.previous + 12,
              },
            },
          }));
        },
        openMonth: () => {
          this.setState((currentState) => ({
            panel: {
              ...currentState.panel,
              previous: {
                ...currentState.previous,
                month: !currentState.panel.previous.month,
                calendar: currentState.panel.previous.month,
                year: false,
              },
            },
          }));
        },

        setMount: (key) => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              previous: datepicker.previous.month(key),
            },
            panel: {
              ...currentState.panel,
              previous: {
                ...currentState.panel.previous,
                month: !currentState.panel.previous.month,
                calendar: !panel.previous.calendar,
                year: false,
              },
            },
            prevmonth: !currentState.prevmonth,
          }));
        },
        openYear: () => {
          this.setState((currentState) => ({
            panel: {
              ...currentState.panel,
              previous: {
                ...currentState.previous,
                month: false,
                calendar: currentState.panel.previous.year,
                year: !currentState.panel.previous.year,
              },
            },
          }));
        },
        setYear: (val, asNext) => {
          if (!asNext) {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                previous: currentState.datepicker.previous.year(val),
              },
              panel: {
                ...currentState.panel,
                previous: {
                  ...currentState.panel.previous,
                  year: !currentState.panel.previous.year,
                  calendar: !currentState.panel.previous.calendar,
                },
              },
              prevyear: !currentState.prevyear,
            }));
          }
        },
      },
      next: {
        date: () => {
          return getPreviousDate(datepicker.next)
            .concat(getCurrentDate(datepicker.next))
            .concat(getNextDate(datepicker.next))
            .map((v) => {
              v.today = v.isToday();
              v.active = datepicker.next.month() === v.month();
              v.off = datepicker.next.month() !== v.month();
              v.sunday = v.day() === 0;
              v.disabled = getDisableDate(v, this.disableDateFunc) && !v.isToday() && !this.inRangeDate(v);
              v.inRange = () => {
                if (this.props.asSingle && !this.props.useRange) {
                  return datepicker.next.month() !== v.month();
                }
              };
              v.hovered = () => {
                if (hoverValue.length > 1) {
                  return (
                    (v.isBetween(hoverValue[0], hoverValue[1], 'date', '()') ||
                      v.isBetween(hoverValue[1], hoverValue[0], 'date', '()')) &&
                    datepicker.next.month() === v.month()
                  );
                }
                return false;
              };
              v.duration = () => {
                return false;
              };
              return v;
            });
        },
        month: datepicker.next && datepicker.next.format(this.props.formatter.month),
        year: datepicker.next && datepicker.next.year(),
        years: () => {
          return Array.from(
            {
              length: 12,
            },
            (v, k) => datepicker.year.next + k,
          );
        },
        onPrevious: () => {
          const previousData =
            this.state.datepicker.next.diff(this.state.datepicker.previous, 'month') === 1 ||
            this.state.datepicker.next.diff(this.state.datepicker.previous, 'month') === 0
              ? this.state.datepicker.previous.subtract(1, 'month')
              : this.state.datepicker.previous;
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              next: currentState.datepicker.next.subtract(1, 'month'),
              previous: previousData,
            },
          }));
        },
        onNext: () => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              next: currentState.datepicker.next.add(1, 'month'),
            },
          }));
        },
        onPreviousYear: () => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              year: {
                ...currentState.datepicker.year,
                next: currentState.datepicker.year.next - 12,
              },
            },
          }));
        },
        onNextYear: () => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              year: {
                ...currentState.datepicker.year,
                next: currentState.datepicker.year.next + 12,
              },
            },
          }));
        },
        openMonth: () => {
          this.setState((currentState) => ({
            panel: {
              ...currentState.panel,
              next: {
                ...currentState.next,
                month: !currentState.panel.next.month,
                calendar: currentState.panel.next.month,
                year: false,
              },
            },
          }));
        },
        setMount: (key) => {
          this.setState((currentState) => ({
            datepicker: {
              ...currentState.datepicker,
              next: datepicker.next.month(key),
            },
            panel: {
              ...currentState.panel,
              next: {
                ...currentState.panel.next,
                month: !currentState.panel.next.month,
                calendar: !panel.next.calendar,
                year: false,
              },
            },
            nextmonth: !currentState.nextmonth,
          }));
        },
        openYear: () => {
          this.setState((currentState) => ({
            panel: {
              ...currentState.panel,
              next: {
                ...currentState.next,
                month: false,
                calendar: currentState.panel.next.year,
                year: !currentState.panel.next.year,
              },
            },
          }));
        },
        setYear: (val, asNext) => {
          if (asNext) {
            this.setState((currentState) => ({
              datepicker: {
                ...currentState.datepicker,
                next: currentState.datepicker.next.year(val),
              },
              panel: {
                ...currentState.panel,
                next: {
                  ...currentState.panel.next,
                  year: !currentState.panel.next.year,
                  calendar: !currentState.panel.next.calendar,
                  month: false,
                },
              },
              nextyear: !currentState.nextyear,
            }));
          }
        },
      },
    };
  };

  datepickerClasses = (date) => {
    const { hoverValue, applyValue, selection } = this.state;
    const { today, active, off, disabled } = date;

    let classes, startedDate, endedDate;
    if (this.isRange()) {
      if (isArray(this.state.modelValue)) {
        if (selection) {
          const [start, end] = applyValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          if (this.props.autoApply) {
            const [start, end] = this.state.modelValue;
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          } else {
            const [start, end] = applyValue;
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          }
        }
      } else if (isObject(this.state.modelValue)) {
        if (selection) {
          const [start, end] = hoverValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          if (this.props.autoApply) {
            const [start, end] = this.state.modelValue ? Object.values(this.state.modelValue) : [false, false];
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          } else {
            const [start, end] = applyValue;
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          }
        }
      } else {
        if (selection) {
          const [start, end] = hoverValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          if (this.props.autoApply) {
            const [start, end] = this.state.modelValue
              ? this.state.modelValue.split(this.props.separator)
              : [false, false];
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          } else {
            const [start, end] = applyValue;
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          }
        }
      }
    } else {
      if (isArray(this.state.modelValue)) {
        if (this.props.autoApply) {
          if (this.state.modelValue.length > 0) {
            const [start] = this.state.modelValue;
            startedDate = dayjs(start);
          }
        } else {
          const [start] = applyValue;
          startedDate = start && dayjs(start);
        }
      } else if (isObject(this.state.modelValue)) {
        if (this.props.autoApply) {
          if (this.state.modelValue) {
            const [start] = Object.values(this.state.modelValue);
            startedDate = dayjs(start);
          }
        } else {
          const [start] = applyValue;
          startedDate = start && dayjs(start);
        }
      } else {
        if (this.props.autoApply) {
          if (this.state.modelValue) {
            const [start] = this.state.modelValue.split(this.props.separator);
            startedDate = dayjs(start);
          }
        } else {
          const [start] = applyValue;
          startedDate = start && dayjs(start);
        }
      }
    }
    if (active) {
      classes = classNames(
        {
          'text-primary-500 font-semibold dark:text-primary-400 rounded-full': today,
        },
        {
          'text-primary-600 font-normal disabled:text-primary-500 disabled:cursor-not-allowed rounded-full': disabled,
        },
        {
          'text-primary-700 font-semibold dark:text-primary-100 rounded-full': date.isBetween(
            startedDate,
            endedDate,
            'date',
            '()',
          ),
        },
        {
          'text-primary-600 font-semibold dark:text-primary-200 rounded-full':
            !today && !disabled && !date.isBetween(startedDate, endedDate, 'date', '()'),
        },
      );
    }
    if (off) {
      classes = classNames(`text-primary-400 font-light disabled:cursor-not-allowed rounded-full`);
    }
    if (startedDate && endedDate && !off) {
      if (date.isSame(startedDate, 'date')) {
        classes = classNames(
          {
            'bg-primary text-white font-bold rounded-l-full disabled:cursor-not-allowed': endedDate.isAfter(
              startedDate,
              'date',
            ),
          },
          {
            'bg-primary text-white font-bold rounded-r-full disabled:cursor-not-allowed': !endedDate.isAfter(
              startedDate,
              'date',
            ),
          },
        );
        if (startedDate.isSame(endedDate, 'date')) {
          classes = classNames('bg-primary text-white font-bold rounded-full disabled:cursor-not-allowed');
        }
      }
      if (date.isSame(endedDate, 'date')) {
        classes = classNames(
          {
            'bg-primary text-white font-bold rounded-r-full disabled:cursor-not-allowed': endedDate.isAfter(
              startedDate,
              'date',
            ),
          },
          {
            'bg-primary text-white font-bold rounded-l-full disabled:cursor-not-allowed': !endedDate.isAfter(
              startedDate,
              'date',
            ),
          },
        );
        if (startedDate.isSame(endedDate, 'date')) {
          classes = classNames('bg-primary text-white font-bold rounded-full disabled:cursor-not-allowed');
        }
      }
    } else if (startedDate) {
      if (date.isSame(startedDate, 'date') && !off) {
        classes = classNames('bg-primary text-white font-bold rounded-full disabled:cursor-not-allowed');
      }
    }

    return classes;
  };

  clearPicker = () => {
    this.setState({
      pickerValue: '',
    });
    if (isArray(this.state.modelValue)) {
      this.setState({ modelValue: [] });
    } else if (isObject(this.state.modelValue)) {
      const obj = {};
      const [start, end] = Object.keys(this.state.modelValue);
      obj[start] = '';
      obj[end] = '';
      this.setState({ modelValue: obj });
    } else {
      this.setState({ modelValue: '' });
    }
    this.setState({
      applyValue: [],
    });
  };

  betweenRangeClasses = (date) => {
    const { hoverValue, applyValue } = this.state;
    let classes, startedDate, endedDate;
    classes = '';
    if (!this.isRange()) return classes;
    if (isArray(this.state.modelValue)) {
      if (hoverValue.length > 1) {
        const [start, end] = hoverValue;
        startedDate = start && dayjs(start);
        endedDate = end && dayjs(end);
      } else {
        if (this.props.autoApply) {
          const [start, end] = this.state.modelValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          if (applyValue.length > 0) {
            const [start, end] = applyValue;
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          }
        }
      }
    } else if (isObject(this.state.modelValue)) {
      if (hoverValue.length > 1) {
        const [start, end] = hoverValue;
        startedDate = start && dayjs(start);
        endedDate = end && dayjs(end);
      } else {
        if (this.props.autoApply) {
          if (this.state.modelValue) {
            const [start, end] = Object.values(this.state.modelValue);
            startedDate = start && dayjs(start);
            endedDate = end && dayjs(end);
          }
        } else {
          const [start, end] = applyValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        }
      }
    } else {
      if (hoverValue.length > 1) {
        const [start, end] = hoverValue;
        startedDate = start && dayjs(start);
        endedDate = end && dayjs(end);
      } else {
        if (this.props.autoApply) {
          const [start, end] = this.state.modelValue
            ? this.state.modelValue.split(this.props.separator)
            : [false, false];
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        } else {
          const [start, end] = applyValue;
          startedDate = start && dayjs(start);
          endedDate = end && dayjs(end);
        }
      }
    }

    if (startedDate && endedDate) {
      if (date.isSame(startedDate, 'date')) {
        if (endedDate.isBefore(startedDate)) {
          classes += ` rounded-r-full inset-0`;
        }
        if (startedDate.isBefore(endedDate)) {
          classes += ` rounded-l-full inset-0`;
        }
      } else if (date.isSame(endedDate, 'date')) {
        if (endedDate.isBefore(startedDate)) {
          classes += ` rounded-l-full inset-0`;
        }
        if (startedDate.isBefore(endedDate)) {
          classes += ` rounded-r-full inset-0`;
        }
      } else {
        classes += ` inset-0`;
      }
    }
    return classes;
  };

  applyDate = () => {
    const { applyValue, pickerValue } = this.state;
    if (applyValue.length < 1) return false;
    let date;
    if (this.isRange()) {
      const [startedDate, endedDate = startedDate] = applyValue;
      if (endedDate.isBefore(startedDate)) {
        date = getToValueFromArray({ previous: endedDate, next: startedDate }, this.props);
      } else {
        date = getToValueFromArray({ previous: startedDate, next: endedDate }, this.props);
      }
    } else {
      const [startedDate] = applyValue;
      date = startedDate;
    }
    if (this.isRange()) {
      const [startedDate, endedDate] = date.split(this.props.separator);

      if (isArray(this.state.modelValue)) {
        const [s, e] = sortDates(this.state.applyValue);
        this.setState({
          modelValue: [s, e],
        });
      } else if (isObject(this.state.modelValue)) {
        const obj = {};
        const [start, end] = Object.keys(this.state.modelValue);
        obj[start] = startedDate;
        obj[end] = endedDate;
        this.setState({
          modelValue: obj,
        });
      } else {
        this.setState({
          modelValue: getToValueFromArray(
            {
              previous: dayjs(startedDate),
              next: dayjs(endedDate),
            },
            this.props,
          ),
        });
      }
      this.setState({
        pickerValue: date,
      });
    } else {
      this.setState({
        pickerValue: date.format(this.props.formatter.date),
      });
      if (isArray(this.state.modelValue)) {
        this.setState({ modelValue: [date.format(this.props.formatter.date)] });
      } else if (isObject(this.state.modelValue)) {
        const obj = {};
        const [start] = Object.keys(this.state.modelValue);
        obj[start] = pickerValue;
        this.setState({ modelValue: obj });
      } else {
        this.setState({ modelValue: pickerValue });
      }
    }
    this.force();
    this.setState({ isShow: true });
    this.updateDatePrefix();
  };

  refreshDatepicker = () => {
    if (Object.keys(this.prefixValue || {}).length) {
      const [s, e] = this.state.modelValue;
      const { start, end } = getDateRange(this.prefixValue.key, this.prefixValue.diff, this.props.minDate);
      const formatedStartDate = start.startOf('day');
      const formatedEndDate = end.startOf('day');
      if (!formatedStartDate.isSame(dayjs(s)) || !formatedEndDate.isSame(dayjs(e))) {
        this.refresh();
        this.setState({ modelValue: [formatedStartDate, formatedEndDate] });
      }
    }
  };

  updateDatePrefix = () => {
    let result;
    defaultShortcuts.forEach((shortcut) => {
      const { key, diff } = shortcut;
      const { start, end } = getDateRange(key, diff, this.props.minDate);
      const [started, ended] = sortDates(this.state.modelValue);
      const finalValue = started && ended && started.valueOf() === start.valueOf() && ended.valueOf() === end.valueOf();
      if (finalValue) {
        result = shortcut;
      }
    });
    this.prefixValue = result;
  };

  isDirty = () => {
    const [start, end] = sortDates(this.state.applyValue);
    const [initial, final] = this.props.initialValues;
    if (!(start && end)) return true;
    return !!(dayjs(start).isSame(dayjs(initial)) && dayjs(end).isSame(dayjs(final)));
  };

  cancelButton = () => {
    return (
      <Button
        onClick={this.hide.bind()}
        text={this.props.translate('card.button.cancel')}
        shape={ButtonShapes.circle}
        color='secondary'
        data-testid={TEST_IDS.generic.buttons.cancel}
        className='border-primary text-primary border-2 active:focus:ring-white !bg-white'
      />
    );
  };

  calendarContent = () => {
    return (
      <div
        date-testid={TEST_IDS.specific.datePicker.dropdownContainer}
        className={`!text-gray-charcoal w-full bg-white border rounded-15 border-black/10 h-full overflow-scroll sm-initial:overflow-hidden`}
      >
        <div className='shortcut-calendar-container flex flex-wrap justify-center p-3'>
          {this.props.shortcuts && (
            <Shortcut
              tooltipContent={this.props.tooltipContent}
              setToDate={this.setToDate}
              checkIfSelected={this.checkIfSelected}
              checkifDisable={this.checkifDisable}
              shortcuts={this.props.shortcuts}
              isRange={this.isRange()}
              asSingle={this.props.asSingle}
            />
          )}
          <div className='picker-wrapper relative flex flex-wrap p-1'>
            {this.isRange() && !this.props.asSingle && (
              <div className='divider-wrapper hidden absolute inset-0 justify-center items-center'>
                <div className='divider-row w-8 h-1 bg-transparent'></div>
              </div>
            )}
            <div
              className={`left-picker relative w-full ${
                this.isRange() && !this.props.asSingle && 'mb-3 left-picker-single '
              }`}
            >
              <Header panel={this.state.panel.previous} calendar={this.calendar().previous} />

              <div className='calendar-wrapper px-0.5'>
                {this.state.panel.previous.month && (
                  <Month months={this.months()} updateMonth={this.calendar().previous.setMount} />
                )}

                {this.state.panel.previous.year && (
                  <Year years={this.calendar().previous.years()} setYear={this.calendar().previous.setYear} />
                )}

                {this.state.panel.previous.calendar && (
                  <div>
                    <Week weeks={this.weeks()} />

                    <Calendar
                      tooltipContent={this.props.tooltipContent}
                      betweenRangeClasses={this.betweenRangeClasses}
                      isRange={this.isRange}
                      calendar={this.calendar().previous}
                      isBetweenRange={this.isBetweenRange}
                      datepickerClasses={this.datepickerClasses}
                      setDate={this.setDate}
                    />
                  </div>
                )}
              </div>
            </div>

            {this.isRange() && !this.props.asSingle && (
              <div className='right-picker relative w-full overflow-hidden mt-3'>
                <Header panel={this.state.panel.next} calendar={this.calendar().next} asPrevOrNext={true} />

                <div className='calendar-wrapper px-0.5'>
                  {this.state.panel.next.month && (
                    <Month months={this.months()} updateMonth={this.calendar().next.setMount} />
                  )}

                  {this.state.panel.next.year && (
                    <Year
                      years={this.calendar().next.years()}
                      setYear={this.calendar().next.setYear}
                      asPrevOrNext={true}
                    />
                  )}

                  {this.state.panel.next.calendar && (
                    <div>
                      <Week weeks={this.weeks()} />

                      <Calendar
                        tooltipContent={this.props.tooltipContent}
                        betweenRangeClasses={this.betweenRangeClasses}
                        isRange={this.isRange}
                        calendar={this.calendar().next}
                        isBetweenRange={this.isBetweenRange}
                        datepickerClasses={this.datepickerClasses}
                        setDate={this.setDate}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        {!this.props.autoApply ? (
          <div className={classNames('bg-white z-10 sticky bottom-0')}>
            <div className='mx-1 py-2 border-t border-black/10 dark:border-primary-700'>
              <div className='flex flex-row-reverse justify-around footer-wrapper'>
                <Button
                  text={this.props.translate('card.button.apply')}
                  disabled={this.isDirty()}
                  data-testid={TEST_IDS.generic.buttons.apply}
                  onClick={() => {
                    this.applyDate();
                    this.hide()?.bind();
                  }}
                  shape={ButtonShapes.circle}
                  className='min-w-[100px]'
                />
                <div className='mr-2 footer-cancel-btn'>{this.cancelButton()}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className='hidden-footer sticky bottom-0 bg-white z-10'>
            <div className='mx-2 py-2 border-t border-black/10 dark:border-primary-700 flex justify-end'>
              <div className='footer-wrapper'>{this.cancelButton()}</div>
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { isMobile, windowHeight, size = 'normal', showPrefix = false } = this.props;

    let placement;

    if (this.props.placement) {
      placement = this.props.placement;
    } else {
      placement = windowHeight < 840 ? 'right' : 'bottom';
    }

    return (
      <>
        {isMobile && (
          <ModalWrapper isModalOpen={this.state.isShow} closeModal={() => this.hide()}>
            {this.calendarContent()}
          </ModalWrapper>
        )}
        <TooltipWrapper
          useDefaultStyle={false}
          placement={placement}
          interactive
          arrow={false}
          trigger='click'
          open={!isMobile && this.state.isShow}
          onOpenChange={(open) => this.setState({ isShow: open })}
          content={this.calendarContent()}
        >
          <div
            ref={this.setInputRef}
            id='litepie'
            data-testid={TEST_IDS.specific.datePicker.toggleBtn}
            className={classNames('h-full relative overflow-hidden', {
              'litepie-datepicker-overlay': this.props.overlay,
            })}
            onClick={(e) => e.preventDefault()}
          >
            <label className='h-full relative block'>
              <input
                type='text'
                className={classNames(
                  'absolute cursor-pointer rounded-12 h-full block pl-12 pr-3 py-2.5 overflow-hidden text-sm placeholder-primary transition-colors bg-white-snow focus:ring-2 focus:ring-primary focus:ring-opacity-10 focus:outline-none',
                  {
                    'rounded-9px': size === 'small',
                    'rounded-12': size === 'medium',
                  },
                )}
                readOnly
              />
              <span
                className={classNames(
                  'flex items-center gap-5 justify-between cursor-pointer border-gray-concrete border-2 h-full relative pl-10 pr-9px py-9px text-sm placeholder-primary transition-colors border-none bg-white-snow',
                  {
                    'rounded-9px': size === 'small',
                    'rounded-12': size === 'medium',
                  },
                )}
              >
                <span
                  className={classNames('2xl:flex text-15 font-semibold leading-6 whitespace-nowrap', {
                    hidden: !showPrefix || !this.prefixValue,
                  })}
                >
                  {this.prefixValue && (
                    <>{this.props.translate(this.prefixValue.transKey, { interval: this.prefixValue.diff })}</>
                  )}
                </span>
                <span className='flex items-center grow overflow-hidden'>
                  <span className='text-12 font-medium leading-18 truncate'>{this.state.pickerValue} </span>
                  <DropdownArrow
                    className='ml-initial'
                    iconClassName={classNames('ml-[2px]', {
                      'w-6px': size === 'small',
                      'w-8px': size === 'medium',
                    })}
                    open={this.state.isShow}
                  />
                </span>
              </span>
              <span className='absolute inset-y-0 left-0 inline-flex items-center rounded-md overflow-hidden'>
                <div
                  className={classNames(
                    'px-1.5 py-1 focus:outline-none text-primary-400 dark:text-opacity-70 rounded-md',
                    {
                      'ml-2': size === 'medium',
                      'ml-1': size === 'small',
                    },
                  )}
                >
                  <Clock strokeColor='#3C3C3C' className='w-[15px]' />
                </div>
              </span>
            </label>
          </div>
        </TooltipWrapper>
      </>
    );
  }
}

LitepieDatepicker.defaultProps = {
  asSingle: false,
  useRange: false,
  overlay: false,
  placeholder: 'datePicker.option.chooseDateRange',
  i18n: 'en',
  autoApply: false,
  shortcuts: [],
  separator: ' - ',
  formatter: {
    date: 'L',
    month: 'MMM',
  },
  startFrom: new Date(),
  initialValues: [],
  updatedValues: () => undefined,
  minDate: false,
  maxDate: false,
  tooltipContent: null,
};

export default LitepieDatepicker;

LitepieDatepicker.propTypes = {
  tooltipContent: PropTypes.string,
  placement: PropTypes.string,
  asSingle: PropTypes.bool,
  useRange: PropTypes.bool,
  overlay: PropTypes.bool,
  placeholder: PropTypes.string,
  i18n: PropTypes.string,
  autoApply: PropTypes.bool,
  shortcuts: PropTypes.array,
  separator: PropTypes.string,
  formatter: PropTypes.object,
  startFrom: PropTypes.any,
  initialValues: PropTypes.array,
  updatedValues: PropTypes.func,
  minDate: PropTypes.instanceOf(dayjs),
  maxDate: PropTypes.instanceOf(dayjs),
  shouldRefreshDatePicker: PropTypes.bool,
  translate: PropTypes.func,
  isMobile: PropTypes.bool,
  windowHeight: PropTypes.number,
  size: PropTypes.oneOf(['small', 'medium']),
  showPrefix: PropTypes.bool,
};
