import { useLocale, useTranslate } from '@va/localization';
import { useWindowDimensions } from '@va/util/hooks';
import classNames from 'classnames';
import dayjs from 'dayjs';
import moment from 'moment';
import LitepieDatepicker from './LitepieDatepicker';

type DatePickerProps = {
  startDate: moment.Moment;
  endDate: moment.Moment;
  minDate: dayjs.Dayjs;
  maxDate: dayjs.Dayjs;
  tooltipContent?: string;
  shouldRefreshDatePicker?: boolean;
  onPeriodChange: (date: [any, any]) => void;
  placement?: string;
  size?: 'small' | 'medium';
};

const DatePicker = ({
  startDate,
  endDate,
  minDate,
  maxDate,
  onPeriodChange,
  tooltipContent,
  shouldRefreshDatePicker,
  placement,
  size = 'medium',
}: DatePickerProps) => {
  const { locale } = useLocale();

  const translate = useTranslate();
  const { isMobile, height } = useWindowDimensions();
  const initialValues = [
    // @ts-ignore
    dayjs(startDate.toString()).tz(window.timezone),
    // @ts-ignore
    dayjs(endDate.toString()).tz(window.timezone).endOf('day'),
  ];

  return (
    <div
      className={classNames('overflow-hidden', {
        'h-12': size === 'medium',
        'h-9': size === 'small',
      })}
    >
      <LitepieDatepicker
        tooltipContent={tooltipContent}
        i18n={locale}
        placement={placement}
        initialValues={initialValues}
        updatedValues={onPeriodChange}
        minDate={minDate}
        maxDate={maxDate}
        shouldRefreshDatePicker={shouldRefreshDatePicker}
        translate={translate}
        isMobile={isMobile}
        windowHeight={height}
        size={size}
      />
    </div>
  );
};

export default DatePicker;
