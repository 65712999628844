import { modalName } from '@va/constants';
import { OPEN_MODAL } from '@va/dashboard/actions/ui';
import { getAccountInformation } from '@va/dashboard/selectors/api';
import { useTranslate } from '@va/localization';
import { START_CHANGE_EMAIL_FLOW, START_DELETE_ACCOUNT_FLOW, updateUserInfo } from '@va/standalone/shared/actions';
import { modalName as standaloneModals } from '@va/standalone/shared/constants';
import { DefaultWebsiteSelector } from '@va/standalone/shared/feature-website-selector';
import { isUserLoggedInWithCustomUrl } from '@va/standalone/shared/helpers';
import { isAgencyOwner } from '@va/standalone/shared/selectors';
import { CloseIconWithBorder, Edit } from '@va/svg-visa-icons';
import { Button, Paragraph, fontWeights } from '@va/ui/design-system';
import { getHowDidYouFindUsOptions } from '@va/util/helpers';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChangePassword from 'standalone/components/AccountSettings/change-password/ChangePassword';
import './AccountDetails.scss';
import AccountDetailsModal from './AccountDetailsModal';
import BusinessDetails from './business-details/BusinessDetails';
import DetailsSection from './common/DetailsSection';
import InfoField from './common/InfoField';
import { NotificationSettings } from './newsletter-settings/NotificationSettings';
import { HowDidYouFindUsDropdown } from './personal-details/HowDidYouFindUsDropdown';

const AccountDetails = () => {
  const agencyOwner = useSelector(isAgencyOwner);
  return (
    <div className='account-details-wrapper'>
      <UserDetails />
      {(!isUserLoggedInWithCustomUrl() || (isUserLoggedInWithCustomUrl() && agencyOwner)) && <BusinessDetails />}
      <NotificationSettings />
      <AccountOptions />
    </div>
  );
};

export default AccountDetails;

const UserDetails = () => {
  const translate = useTranslate();
  const accountInfo = useSelector(getAccountInformation);
  const dispatch = useDispatch();
  const howDidYouFindUsOptions = useMemo(() => getHowDidYouFindUsOptions(translate), [translate]);

  const getSelectedHowDidYouFindUs = useCallback(
    (howDidYouFindUsId) => {
      return howDidYouFindUsOptions.find((option) => Number(option.value) === Number(howDidYouFindUsId));
    },
    [howDidYouFindUsOptions],
  );

  return (
    <>
      <ChangePassword />
      <AccountDetailsModal />
      <DetailsSection
        title={translate('account.userDetails.title')}
        onButtonClick={() => dispatch({ type: OPEN_MODAL, modalName: standaloneModals.ACCOUNT_INFO })}
      >
        <InfoField
          label={translate('form.placeholders.email')}
          value={accountInfo.email}
          icon={
            <Edit
              onClick={() => dispatch({ type: START_CHANGE_EMAIL_FLOW })}
              className='icon'
              color={window.PRIMARY_COLOR}
            />
          }
        />
        <div className='flex justify-between mt-4 md:mt-8 flex-column lg:flex-row'>
          <div className='flex w-full flex-column md:flex-row'>
            <InfoField
              label={translate('form.placeholders.firstName')}
              additionalClass='w-full md:w-2/4'
              value={accountInfo.firstName}
            />
            <InfoField
              label={translate('form.placeholders.lastName')}
              additionalClass='w-full md:w-2/4 mt-4 md:mt-0'
              value={accountInfo.lastName}
            />
          </div>
          <InfoField
            additionalClass='mt-4 md:mt-8 lg:mt-0 w-full md:w-2/4 cursor-pointer'
            label={translate('form.placeholders.password')}
            value={<div className='text-green-persian'>{translate('account.userDetails.changePassword')}...</div>}
            onClick={() => dispatch({ type: OPEN_MODAL, modalName: modalName.changePassword })}
          />
        </div>
        <div className='flex flex-col mt-4 md:mt-8 gap-6px'>
          <Paragraph colorClassName='text-gray-devilSolid' weight={fontWeights.medium}>
            {translate('account.accountOptions.personalData.howDidYouFindUs.title')}
          </Paragraph>
          <HowDidYouFindUsDropdown
            label={translate('account.accountOptions.personalData.howDidYouFindUs.label')}
            placeholder={translate('account.accountOptions.personalData.howDidYouFindUs.placeholder')}
            selectedOption={getSelectedHowDidYouFindUs(accountInfo.findUs)}
            options={howDidYouFindUsOptions}
            onChange={(option) => {
              dispatch(updateUserInfo({ findUs: option.value }));
            }}
          />
        </div>
      </DetailsSection>
    </>
  );
};

const AccountOptions = () => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  return (
    <DetailsSection title={translate('account.accountOptions.title')} additionalClass='bg-gray-alabaster'>
      <div className='flex flex-col gap-30px'>
        <div className='flex flex-col gap-6px'>
          <Paragraph colorClassName='text-gray-devilSolid' weight={fontWeights.medium}>
            {translate('account.accountOptions.preferredDefaultWebsite.title')}
          </Paragraph>
          <DefaultWebsiteSelector />
        </div>
        <div className='flex flex-column'>
          <div className='font-medium text-lg leading-normal tracking-default text-mine-shaft'>
            {translate('button.deleteAccount')}
          </div>
          <div className='my-3 '>
            <Button
              color='destructive'
              text={translate('button.deleteAccount')}
              icon={(_, color) => <CloseIconWithBorder color={color} className='h-5 w-5' />}
              className='!bg-red-negative !bg-opacity-10 !text-red-negative flex justify-center w-full md:w-auto'
              onClick={() => dispatch({ type: START_DELETE_ACCOUNT_FLOW })}
            />
          </div>
          <div className='text-15 leading-normal tracking-tightestDefault text-gray-dusty max-w-md'>
            {translate('account.accountOptions.deleteAccountInfo')}
          </div>
        </div>
      </div>
    </DetailsSection>
  );
};
