import { apiStatus } from '@va/constants';
import {
  ADD_CONVERSION_PAGE,
  ADD_CONVERSION_TYPE_FAILED,
  REFRESH_TOKEN,
  REFRESH_TOKEN_ENDED,
  REMOVE_CONVERSION_PAGE,
  RESET_REQUEST,
  RESET_REQUEST_STATUS,
  SET_REQUEST_STATUS,
  START_EDITING_CONVERSION_TYPE,
} from '@va/dashboard/actions/api';
import Immutable from 'immutable';
import { applyReducers } from '../reducers.helpers';
import * as Account from './account';
import * as Admin from './admin';
import * as ConversionTypes from './conversionTypes';
import * as DynamicPages from './dynamicPages';
import { getModulePermissions } from './modulePermissions';
import * as SessionReplays from './sessionReplays';
import * as Settings from './settings';
import * as Support from './support';
import * as Visitors from './visitors';
import * as Website from './website';

const apiReducer = (state = Immutable.Map(), action) => {
  const conversionPagesPath = ['activeEditingConversionType', 'pages'];
  switch (action.type) {
    case RESET_REQUEST:
      return state.set(action.requestName, Immutable.Map({ status: apiStatus.NONE }));
    case RESET_REQUEST_STATUS:
      return state.setIn([action.requestName, 'status'], apiStatus.NONE);
    case SET_REQUEST_STATUS:
      return state.setIn([action.requestName, 'status'], action.requestStatus);
    case REFRESH_TOKEN:
      return state.set('isRefreshingToken', true);
    case REFRESH_TOKEN_ENDED:
      return state.set('isRefreshingToken', false);
    case START_EDITING_CONVERSION_TYPE:
      return state.set('activeEditingConversionType', action.data);
    case ADD_CONVERSION_PAGE:
      return state.getIn(conversionPagesPath)
        ? state.updateIn(conversionPagesPath, (l) => l.push(action.data))
        : state.setIn(conversionPagesPath, Immutable.List([action.data]));
    case REMOVE_CONVERSION_PAGE: {
      const pages = state.getIn(conversionPagesPath);
      return state.setIn(
        conversionPagesPath,
        pages.filter((item) => item.get('key') !== action.id),
      );
    }
    case ADD_CONVERSION_TYPE_FAILED:
      return state.setIn(conversionPagesPath, null);
    default:
      return applyReducers(state, action, {
        getVisitor: Visitors.getVisitor,
        getLatestVisitorsOss: Visitors.getLatestVisitorsOss,
        getLatestVisitorsBrowsers: Visitors.getLatestVisitorsBrowsers,
        latestVisitorsMap: Visitors.latestVisitorsMap,
        exportVisitors: Visitors.exportVisitors,

        settings: Settings.getSettings,
        notificationSettings: Settings.getNotificationSettings,
        updateSettings: Settings.updateSettings,

        updateSettingsNotifications: Settings.updateSettingsNotifications,
        updateAccountInfo: Settings.updateAccountInfo,

        sendFeedback: Support.sendFeedback,
        sendReview: Support.sendReview,

        createWebsite: Website.createWebsite,
        updateWebsiteFlags: Website.updateWebsiteFlags,
        agreement: Website.agreement,
        getAgreement: Website.getAgreement,
        signDPA: Website.signDPA,
        getFeaturePermissions: Website.getFeaturePermissions,
        getAnnouncements: Website.getAnnouncements,
        getPrivacySettings: Website.getPrivacySettings,

        generateImpersonateToken: Admin.generateImpersonateToken,

        promptSsrFeature: SessionReplays.promptSsrFeature,
        getVideoTrackingCode: SessionReplays.getVideoTrackingCode,
        getRecordingsCount: SessionReplays.getRecordingsCount,
        createSsrSettings: SessionReplays.createSsrSettings,
        getSsrSettings: SessionReplays.getSsrSettings,
        updateSsrSettings: SessionReplays.updateSsrSettings,
        updateSessionExtras: SessionReplays.updateSessionExtras,

        getDynamicPages: DynamicPages.getDynamicPages,
        addDynamicPage: DynamicPages.addDynamicPage,
        editDynamicPage: DynamicPages.editDynamicPage,
        removeDynamicPage: DynamicPages.removeDynamicPage,

        getConversionTypes: ConversionTypes.getConversionTypes,
        addConversionType: ConversionTypes.addConversionType,
        editConversionType: ConversionTypes.editConversionType,
        removeConversionType: ConversionTypes.removeConversionType,

        setUserFlag: Account.setUserFlag,
        accountInformation: Account.getAccountInformation,
        getModulePermissions: getModulePermissions,
      });
  }
};
export default apiReducer;
