import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { fontWeightClassName, fontWeights } from './font-weights';
import { HeadingProps } from './types';

export const Heading1 = ({
  weight = fontWeights.normal,
  colorClassName = 'text-text-dark',
  className,
  children,
}: PropsWithChildren<HeadingProps>) => {
  const classes = classNames(
    'text-5xl leading-54 -tracking-0036 font-primary',
    fontWeightClassName(weight),
    colorClassName,
    className,
  );

  return <h1 className={classes}>{children}</h1>;
};
